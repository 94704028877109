<template>
  <div class="popup_overlay" v-if="basicDetail !== null">
    <div :class="isChangeCss ? 'custom_dialog2 bg-white' : 'custom_dialog m-4'" class=" card rounded-xl" v-click-outside="closeList">
      <div class="">
        <div class="bg-primary flex items-center justify-between p-3">
          <div class=" font-medium text-white heading-4">
            <span class="font-semibold">Text:</span> Visit {{visitDetail.visitNumber}}/{{visitDetail.totalVisits}} for Job #{{visitDetail.jobNumber}}
          </div>
          <div class="pb-0 mb-0">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Close'" @buttonAction="closeList()" />
          </div>
        </div>
        <div>
          <div id="smsContainer" class="overflow-auto card m-2 p-2 rounded-lg" ref="messageContainer" :style="`max-height:` + cardHeight + `px;overflow: auto; !important`" @scroll="handleMSGScroll">
            <div class="w-full">
              <div class="progress-bar" v-if="loadingMoreData">
                <div class="progress-bar-value"></div>
              </div>
            </div>
            <div class="px-2" v-for="(data, index) in leadMsgArray" :key="index">
              <div class="p-1 rounded-md border cust_card bg-gray1" :class="mobileView ?'my-3 ml-8' : 'my-4 ml-20'" v-if="!data.isReply">
                <div class="px-2 w-full whitespace-pre-line font-semibold" :class="mobileView ?'heading-6' : 'heading-6'">{{data.messageTitle}}</div>
                <div class="px-2 w-full whitespace-pre-line" :class="mobileView ?'heading-7' : ''">{{data.messageText}}</div>
                <p class="mb-0 -mt-1"><span class="text-gray4 text-right justify-end flex pr-2" :class="'heading-7'" >Sent: {{data.sendReceiveDate | logTimeShow}}</span></p>
              </div>
              <div class="p-1 rounded-md border cust_card" :class="mobileView ?'my-3 mr-8' : 'my-4 mr-12'" v-if="data.isReply">
                <div class="px-2 w-full whitespace-pre-line font-semibold" :class="mobileView ?'heading-6' : 'heading-6'">{{data.messageTitle}}</div>
                <div class="px-2 w-full whitespace-pre-line" :class="mobileView ?'heading-7' : ''">{{data.messageText}}</div>
                <p class="mb-0 -mt-1"><span class="text-gray4 text-right justify-end flex pr-2" :class="'heading-7'">Received: {{data.sendReceiveDate | logTimeShow}}</span></p>
              </div>
            </div>
            <div v-if="leadMsgArray.length === 0">
              <NoRecords :alertMessage="`No communication found.`" />
            </div>
            <div class="w-full">
              <div class="progress-bar" v-if="showSmsLoader">
                <div class="progress-bar-value"></div>
              </div>
            </div>
          </div>
          <div class="card rounded-md  m-2 p-2 fixed-bottom" ref="test123" v-if="twilioCOnfList.length > 0">
            <div class="cursor-pointer card rounded-md mb-2 p-2">
              <div class="flex items-center"  @click.stop="displayTagSelects = !displayTagSelects">
                <span class="">
                  <p v-if="displayTagSelects"><i class="fas fa-chevron-up text-primary heading-5"></i></p>
                  <span v-if="!displayTagSelects"><i class="fas fa-chevron-down text-primary heading-5"></i></span>
                </span>
                <p class="heading-5 text-text2 pl-2">Send To: <span class="heading-5 text-text2 pl-2">{{ formattedNames }}</span></p>
              </div>
              <div  v-show="displayTagSelects" class="overflow-auto" :style="`max-height:` + cardHeight2 + `px;overflow: auto; !important`">
                <div v-show="basicDetail.contactList !== null" class="pt-1 pl-2 "  v-for="(data, index) in basicDetail.contactList" :key="index">
                  <div><i class="fas fa-user text-gray4 pr-2"></i>{{data.firstName}} {{data.lastName}}</div>
                  <div class="pt-1">
                    <div class="flex items-center " v-if="data.phoneNumber !== ''">
                      <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary" @click="checkboxCheck(data, 'primary', index)" v-model="data.isPrimarysel" >
                      <span class="pl-2 text-text2 cursor-pointer" @click="checkboxCheck(data, 'primary', index)">{{data.phoneNumber | phone}}<span class="heading-7 text-primary pl-1">(primary)</span></span>
                    </div>
                    <div class="flex items-center " v-if="data.otherNumber !== ''">
                      <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary" @click="checkboxCheck(data, 'secend', index)" v-model="data.isSecondorySel" >
                      <span class="pl-2 text-text2 cursor-pointer" @click="checkboxCheck(data, 'secend', index)">{{data.otherNumber | phone}}</span>
                    </div>
                    <div v-if="data.phoneNumber === '' && data.otherNumber === ''">
                      <span class="text-error heading-6">*Customer contact not found, Please add customer number and try again</span>
                    </div>
                  </div>
                  <div v-if="index < basicDetail.contactList.length - 1" class="dividerLight mt-1"></div>
                </div>
              </div>
            </div>
            <div class="py-2 pt-2">
              <TextAreaInput
                  :inputId="'howtoreach'"
                  :inputext="message"
                  :placholderText="`Write your message here`"
                  :lableText="''"
                  :inputType="''"
                  :autoFocus="false"
                  :cols="50"
                  :rows="3"
                  :setReadOnly="false"
                  :fieldError="msgErr !== ''"
                  @keyPressAction="msgErr = ''"
                  @inputChangeAction="(data) => message = data"  />
                  <p class="text-error heading-6">{{msgErr}}</p>
            </div>
            <div class="text-right justify-end flex pr-2">
              <button :disabled="message === '' || selectedNumArr.length === 0" :class="message === '' || selectedNumArr.length === 0 ? 'opacity-20' : ''" type="button" class="bg-primary text-white py-1.5 px-3 font-semibold shadow cursor-pointer  hover:shadow-xl heading-6 rounded-xl" @click="sendMsg()">
                <i class="fas fa-caret-right pr-1"></i>Send
              </button>
            </div>
          </div>
          <div class="card rounded-md m-2 p-2 text-center fixed-bottom" ref="test123" v-if="twilioCOnfList.length === 0">
            <div class="text-error">There is no number in the Twilio configuration or the number is inactive</div>
            <div class="text-text2"><span class="hover:underline hover:text-primary cursor-pointer" @click="routerNew()">Click here</span> to add new configuration</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from "@/api/MyJobApp.js";
import TextAreaInput from '@/View/components/textAreaInput.vue'
import Button from '@/View/components/globalButton.vue'
export default {
  props: ['visitDetail'],
  components: {
    NoRecords,
    Button,
    TextAreaInput
  },
  data() {
    return {
      twilioConfigurationId: 0,
      selectedContact: [],
      isChangeCss: false,
      message: '',
      msgErr: '',
      isSelSecond: false,
      isSelPrimary: false,
      displayTagSelects: false,
      mobileView: false,
      scY: 0,
      scTimer: 0,
      cardHeight2: 0,
      twilioCOnfList: [],
      cardHeight: '',
      selectedNumArr: [],
      basicDetail: null,
      leadMsgArray: [],
      leadMsCount: 0,
      showSmsLoader: false,
      loadingMoreData: false,
      otherStart:0,
      scrollDown: true,
      oldHeight: 0,
    };
  },
  computed: {
    formattedNames() {
      const uniqueNames = this.getUniqueNames();
      return uniqueNames.join(', ');
    }
  },
  watch: {
    leadMsgArray: {
        handler () {
          if (this.leadMsgArray.length > 0 && this.scrollDown) {
            setTimeout(() => {
              this.scrollToLastMessage();
              this.showSmsLoader = false
              this.scrollDown = true
            }, 500);
          } else {
            console.log('this.oldHeight', this.oldHeight);
            setTimeout(() => {
            // const messageContainer = this.$refs.messageContainer;
            //   messageContainer.scrollTop = messageContainer.scrollHeight - this.oldHeight - 200;
            // this.oldHeight = 0
            this.showSmsLoader = false
            }, 500);
          }
        },deep: true
    },
    basicDetail: {
        handler () {
          this.updateSelectedNumbers();
        },deep: true
    },
    selectedNumArr: {
        handler () {
          console.log('selectedNumArr', this.selectedNumArr)
        },deep: true
    },
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {

    this.getSmsList()
    this.getTwilioListing()
    window.scrollTo({top: 0})
    this.resizeWindowHandler();
    this.getBasicDetail()
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleMSGScroll() {
      
        const container = this.$refs.messageContainer;
          this.oldHeight = container.scrollHeight;
          console.log('scrollHeight', container.scrollHeight);
          console.log('scrollTop', container.scrollTop);
        if (container && this.leadMsCount !== this.leadMsgArray.length) {
          this.scrollDown = false
            const scrollTop = container.scrollTop;
            const isScrollNearBottom = scrollTop === 0;

            if (isScrollNearBottom && !this.loadingMoreData) {
                // Set loading flag to prevent multiple rapid triggers
                this.loadingMoreData = true;

                // Use setTimeout to debounce the function call
                setTimeout(() => {
                    // User has scrolled near the bottom, load more data
                    this.loadMoreMessage().finally(() => {
                        // Reset loading flag after data loading completes
                        this.loadingMoreData = false;
                    });
                }, 1000); // Adjust debounce time (in milliseconds) as needed
            }
        }
    },
    async loadMoreMessage() {
        // Save current scroll position
        const container = this.$refs.messageContainer;
        const currentScrollPosition = container.scrollTop;

        // Update otherStart for the next request
        this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' });
        this.otherStart += 20;

        try {
            await this.getSmsList(true); // Assuming otherGetAllAttachment is an async function
        } catch (error) {
            // Handle error scenario
            console.error('Error loading more data:', error);
            throw error; // Propagate the error to the caller
        } finally {
            // Restore previous scroll position
            if (container) {
                container.scrollTop = currentScrollPosition + 300;
            }
        }
    },
    updateSelectedNumbers() {
      this.selectedNumArr = [];
      this.basicDetail.contactList.forEach(contact => {
        if (contact.isPrimarysel && contact.phoneNumber) {
          this.selectedNumArr.push({ 
            contactId: contact.contactId, 
            firstName: contact.firstName, 
            lastName: contact.lastName, 
            toNumber: contact.phoneNumber 
          });
        }
        if (contact.isSecondorySel && contact.otherNumber) {
          this.selectedNumArr.push({ 
            contactId: contact.contactId, 
            firstName: contact.firstName, 
            lastName: contact.lastName, 
            toNumber: contact.otherNumber 
          });
        }
      });
    },
    getUniqueNames() {
      const nameSet = new Set();
      this.selectedNumArr.forEach(contact => {
        const fullName = `${contact.firstName} ${contact.lastName}`;
        nameSet.add(fullName);
      });
      return Array.from(nameSet);
    },
    routerNew () {
      let routeData1 = this.$router.resolve({name: 'Twilioconfiguration'})
      window.open(routeData1.href, '_blank')
    },
    checkboxCheck (data, from, indexA) {
      let contact = this.basicDetail.contactList[indexA];
      if (from === 'primary') {
        this.$set(this.basicDetail.contactList, indexA, {
          ...contact,
          isPrimarysel: !contact.isPrimarysel
        });
      } else {
        this.$set(this.basicDetail.contactList, indexA, {
          ...contact,
          isSecondorySel: !contact.isSecondorySel
        });
      }
      this.updateSelectedNumbers();
    },
    handleScroll () {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
    resizeWindowHandler() {
      this.cardHeight2 = window.innerHeight - 393
      this.cardHeight = window.innerHeight - 313
      // if (window.innerWidth < 684) {
      if (window.innerWidth < 1263) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      if (window.innerWidth < 684) {
        this.isChangeCss = true;
      } else {
        this.isChangeCss = false;
      }
    },
    getBasicDetail () {
      this.$store.dispatch("SetLoader", {  status: true,  message: "Loading...",});
      MyJobApp.GetPhoneBasicDetail(
        'visit',
        this.visitDetail.visitId,
        (response) => {
          this.basicDetail = response.Data
          let primaryData = null
          let primaryDindex = null
          if (this.basicDetail.contactList !== null) {
            this.basicDetail.contactList.forEach((element, i) => {
              element.isSecondorySel = false
              element.isPrimarysel = false
              if (element.isPrimary) {
                primaryData = element
                primaryDindex = i
              }
            });
            if (this.basicDetail.contactList.length === 1) {
            const contact = this.basicDetail.contactList[0];
            
            if (contact.phoneNumber && !contact.otherNumber) {
              // Only phoneNumber is available
              this.$set(this.basicDetail.contactList, 0, {
                ...contact,
                isPrimarysel: true
              });
              this.selectedNumArr.push({
                contactId: contact.contactId,
                firstName: contact.firstName,
                lastName: contact.lastName,
                toNumber: contact.phoneNumber
              });
            } else if (!contact.phoneNumber && contact.otherNumber) {
              // Only otherNumber is available
              this.$set(this.basicDetail.contactList, 0, {
                ...contact,
                isSecondorySel: true
              });
              this.selectedNumArr.push({
                contactId: contact.contactId,
                firstName: contact.firstName,
                lastName: contact.lastName,
                toNumber: contact.otherNumber
              });
            }
          } else if (primaryData  !== null && primaryDindex !== null) {
            this.$set(this.basicDetail.contactList, primaryDindex, {
                ...primaryData,
                isPrimarysel: true
              });
              this.selectedNumArr.push({
                contactId: primaryData.contactId,
                firstName: primaryData.firstName,
                lastName: primaryData.lastName,
                toNumber: primaryData.phoneNumber
              });
          }
          this.updateSelectedNumbers();
          }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    sendMsg () {
      this.showSmsLoader = true
      this.displayTagSelects = false
      if (this.message !== '' && this.selectedNumArr.length > 0) {
        // this.$store.dispatch("SetLoader", {  status: true,  message: "Loading...",});
        MyJobApp.SendTextMessage(
          'visit',
          this.visitDetail.visitId,
          this.visitDetail.customerId,
          this.twilioConfigurationId,
          this.message,
          this.selectedNumArr,
          (response) => {
            this.getSmsList()
            this.message = ''
            // this.closeList()
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (err) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
      }
    },
    getSmsList (loadMorre) {
      this.showSmsLoader = true
      let tempList = []
      if (loadMorre) {
        tempList = this.leadMsgArray 
      } else {
        this.otherStart = 0
      }
      this.$store.dispatch("SetLoader", {  status: true,  message: "Loading...",});
        MyJobApp.getSmsList(
          this.otherStart,
          20,
          '',
          'visit',
          this.visitDetail.visitId,
          (response) => {
            console.log("response", response);
            this.leadMsCount = response.Data.count
            if (loadMorre) {
              let list = response.Data.tableRow !== null ? response.Data.tableRow : []
              list.forEach(e => {
                tempList.unshift({
                  messageTitle: e.sentByFirstName + ' ' + e.sentByLastName.charAt(0) + ' sent sms to '  + e.contactFirstName + ' ' + e.contactLastName.charAt(0) + " on " + this.formatNumber(e.sentToNumber),
                  messageText: e.messageText,
                  isReply: e.isReply,
                  sendReceiveDate: e.addedDateTime
                 })
              });
            } else {
              let list = response.Data.tableRow !== null ? response.Data.tableRow.reverse() : []
              list.forEach(e => {
                tempList.push({
                  messageTitle: e.sentByFirstName + ' ' + e.sentByLastName.charAt(0) + ' sent sms to '  + e.contactFirstName + ' ' + e.contactLastName.charAt(0) + " on " + this.formatNumber(e.sentToNumber),
                  messageText: e.messageText,
                  isReply: e.isReply,
                  sendReceiveDate: e.addedDateTime
                 })
              });
            }
            
             this.leadMsgArray = tempList
              this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (err) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
    },
    formatNumber(num) {
      return num.replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    },
    getTwilioListing() {
        this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
        MyJobApp.GetTwilioList(
          0,
          100,
          '',
          '',
          '',
          'active',
          (response) => {
            this.twilioCOnfList = response.Data.tableRow !== null ? response.Data.tableRow : []
            if (this.twilioCOnfList.length > 0) {
              this.twilioConfigurationId = this.twilioCOnfList[0].twilioConfigurationId
            }
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (err) => {
              this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
              this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
      },
    scrollToLastMessage() {
      console.log('messageContainer', this.$refs.messageContainer)
      if (this.$refs.messageContainer) {
        const messageContainer = this.$refs.messageContainer;
        messageContainer.scrollTop = messageContainer.scrollHeight;
      } else {
        console.log("messageContainer not found");
      }
    },
    closeList() {
      this.$root.$emit("closeSendTextPopup")
    },
  },
};
</script>
<style scoped>
.custom_dialog {
  /* Set fixed width and full height */
  width: 530px;
  height: 99%; /* Full height */
  max-height: none; /* Override max-height to allow full height */
  /* Position the dialog on the right side */
  position: absolute;
  right: 0;
}
.custom_dialog2 {
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  max-height: none;
  position: fixed; /* Fixed positioning to cover the entire viewport */
  top: 0;
  left: 0;
  overflow: hidden; /* Hide overflow */
}
.popup_overlay {
  /* Overlay styling */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align dialog to the right */
  overflow: hidden; /* Ensure no scrollbars appea r */
}
.fixed-bottom {
  position: absolute; /* Position relative to the nearest positioned ancestor */
  bottom: 0; /* Align the bottom of the element with the bottom of its container */
  left: 0; /* Align the left side of the element with the left side of its container */
  width: 100%; /* Make the element span the full width of its container */
  /* Additional styles to ensure it looks good */
  background-color: white; /* Set a background color to distinguish it from the content */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Add a shadow for better visibility */
  z-index: 10; /* Ensure it appears on top of other content */
  left: -8px;
}
#smsContainer {
  /* position: relative; */
}
.progress-bar {
  height: 3px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
</style>
