<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 600px; overflow: auto; max-height: 85%;">
      <div class="relative">
        <div class="">
          <div class="bg-primary flex justify-between p-3 rounded-t-xl">
            <div class=" font-medium text-white heading-3">
                {{selectedMemberData.firstName}} {{selectedMemberData.lastName}}
            </div>
          </div>
          <div class="p-4 text-text1">
            <div v-if="forceStart" class="mb-4">
                <div>
                    <p class="text-gray3 mb-4">Start Time:
                        <span class="text-text1 font-bold">{{showdateTime | logTimeShow}}</span>
                    </p>
                    <div class="flex justify-center flex-wrap p-4" >
                        <div class="mb-4">
                        <DateTimepicker
                            v-model="dateinputText"
                            :title="''"
                            :showOnlyDate="true"
                            :goToday="goToday"
                            :hideTopHeadingDate="false"
                            :hideGoToday="true"
                            :hideBtn="true"
                            :minDate="nowDate"
                            :maxDate="maxDate"
                            :roundedCard="true"
                            @input="updateDate()"
                            >
                        </DateTimepicker>
                        </div>
                        <div>
                            <div class=" px-4 py-2">
                                <p class=" font-semibold">Hour</p>
                                <div class="flex gap-2 items-center">
                                    <div class="">
                                        <button :class="`timePick_btn`"
                                        @click="minusHour">
                                        <span><i class="fa-solid fa-minus"></i></span>
                                        </button>
                                    </div>
                                    <div class="">
                                        <button :class="` cursor-default timePick_btn bg-white text-text1 border border-primary`"
                                        >
                                        <span>{{startHour}}</span>
                                        </button>
                                    </div>
                                    <div class="">
                                        <button :class="selectedTime === 'am' ? 'timePick_btn' : ' timePick_btn2  border border-primary'"
                                        @click="setAm">
                                        <span>AM</span>
                                        </button>
                                    </div>
                                    <div class="">
                                        <button :class="selectedTime === 'pm' ? 'timePick_btn' : ' timePick_btn2  border border-primary'"
                                        @click="setPm">
                                        <span>PM</span>
                                        </button>
                                    </div>
                                    <div class="">
                                    <button :class="`timePick_btn`"
                                        @click="addHour()"><i class="fa-solid fa-plus"></i>
                                    </button>
                                    </div>
                                </div>
                            </div>
                            <div class=" px-4 py-2">
                                <p class=" font-semibold">Minutes</p>
                                <div class="flex gap-2 items-center">
                                    <button v-for="(min) in minutesArr" :key="min"
                                        :class="min === startMin ? 'timePick_btn' : ' timePick_btn2  border border-primary'"
                                        @click="seMinutes(min)" :outlined="min !== startMin">{{min}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            </div>
            <div v-else>
                
                <div>
                    <div class="mb-4 border-b pb-4">
                        <span class="text-gray3">Started On : </span> <span>{{selectedMemberData.jobStartTime | logTimeShow}}</span>
                    </div>
                    <div>
                        <p class=" mb-4 text-center">Add Hrs/Mins spend to this Job</p>
                        <div class=" font-bold mb-2 flex items-center"><span class="mr-4">Spend Time:</span> 

                            <div v-if="spendHr > 0 || spendMin > 0">
                            <span v-if="spendHr <= 9 && spendHr > 0">0{{spendHr}}</span>
                            <span v-else-if="spendHr > 0">{{spendHr}}</span>
                            <span v-if="spendHr > 0"> hr</span>
                            <span v-if="spendMin > 0 && spendHr > 0">,</span>
                            <span v-if="spendMin > 0 && spendMin <= 9">0{{spendMin}}</span>
                            <span v-else-if="spendMin > 0"> {{spendMin}}</span>
                            <span v-if="spendMin > 0"> Mins</span>
                            </div>

                        </div>
                        <div class="flex flex-wrap gap-4 sm:gap-8">
                            <div class="text-left pt-1 pb-1 pl-2 flex items-center">
                                <div class="w-12"><span>Hour:</span></div>
                                <div class="">
                                    <button :disabled="hourInputValue === 0" :class="`timePick_btn ${hourInputValue === 0 ? 'bg-gray3': ''}`"
                                    @click="decreaseHours()"  small>
                                    <span>-</span>
                                    </button>
                                </div>
                                <div class="w-12 flex justify-center items-center">
                                    <span>{{hourInputValue}}</span>
                                </div>
                                <div class="ml-1">
                                <button :disabled="hourInputValue === 18" :class="`timePick_btn ${hourInputValue === 18 ? 'bg-gray3': ''}`"
                                    @click="increaseHours()"  small>+
                                </button>
                                </div>
                            </div>
                            <div class="text-left pt-1 pb-1 pl-2 flex items-center">
                                <div class="w-12"><span>Min:</span></div>
                                <div class="">
                                    <button :disabled="minuteInputValue === 0" :class="`timePick_btn ${minuteInputValue === 0 ? 'bg-gray3': ''}`"
                                    @click="decreaseMin()"  small>
                                    <span>-</span>
                                    </button>
                                </div>
                                <div class="w-12 flex justify-center items-center">
                                    <span>{{minuteInputValue}}</span>
                                </div>
                                <div class="ml-1">
                                <button :disabled="minuteInputValue === 59" :class="`timePick_btn ${minuteInputValue === 59 ? 'bg-gray3': ''}`"
                                    @click="increaseMin()"  small>+
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p v-if="timeError !== ''" class="text-error mb-2">{{timeError}}</p>
            </div>
            <div v-if="!hideCurrent" class="mt-4">
                    <span @click="setCurrentTime" class="text-primary cursor-pointer">Set Current Time</span>
                </div>
          </div>
        </div>
          <div class=" sticky bottom-0 bg-white w-full rounded-b-xl flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
                <Button :disabled="showdateTime === '' || timeError !== ''" :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="`${forceStart ? 'Force Start' : 'Force Stop'}`" @buttonAction="forceJobStart()"/>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import DateTimepicker from '../../../components/dateTimepicker.vue'
// import Textfield from '../../../components/textfield.vue'
import MyJobApp from '@/api/MyJobApp.js'
import moment from 'moment'
export default {
  name: 'sendEmail',
  components: {
    Button,
    DateTimepicker,
    // Textfield,
  },
  data () {
    return {
        timeError: '',
        selectedTime: '',
        goToday: false,
        forceStart: true,
        hideCurrent: true,
        dateinputText: '',
        showdateTime: '',
        hourInputValue: 0,
        startHour: 0,
        startHoursValue: 0,
        startMin: 0,
        minuteInputValue: 0,
        minutesArr: [0, 15, 30, 45],
        nowDate: new Date().toISOString().slice(0, 10),
        maxDate: new Date().toISOString().slice(0, 10),
        spendHr: 0,
        spendMin: 0,
    }
  },
  props: ['visitDetail', 'selectedMemberData'],
   created() {
  },
  mounted () {
    if (this.selectedMemberData.jobStartTime !== '') {
        this.forceStart = false
        this.hideCurrent = false
    } else {
        // let time = new Date()
        var hours = new Date().getHours();
        var minutes = new Date().getMinutes();
        this.selectedTime = hours >= 12 ? 'pm' : 'am';
        // hours = hours % 12;
        let dhour = hours % 12;
        this.startHour = dhour ? dhour : 12; // the hour '0' should be '12'
        this.startHoursValue = hours ? hours : 12; // the hour '0' should be '12'
        this.startMin = minutes < 10 ? minutes : minutes;
        this.dateinputText = new Date()
    }
    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.nowDate = new Date(d).toISOString().slice(0, 10)
  },
  beforeDestroy () {
    // this.$root.$off("setItemSelectedHandler");
  },
  watch: {
    minuteInputValue: {
        handler () {
            if (this.minuteInputValue >= 59) {
                this.minuteInputValue = 59
            }
            if (this.minuteInputValue <= 0) {
                this.minuteInputValue = 0
            }
        }
    },
    startHoursValue: {
      handler () {
        this.setFinalDateTime()
      },
      deep: true
    },
    startMin: {
      handler () {
        this.setFinalDateTime()
      },
      deep: true
    }
  },
  methods: {
    setFinalDateTime (setCurentTime) {
        if (setCurentTime) {
            this.dateinputText = ""
            let value = new Date().setHours(this.startHoursValue, this.startMin, 0, 0)
                this.dateinputText = new Date(value)
                this.showdateTime = this.dateinputText
                let hours = new Date(this.dateinputText).getHours();
                this.selectedTime = hours >= 12 ? 'pm' : 'am';
                let now = new Date();
                let d = new Date(this.dateinputText)
                if (now.getTime() < d.getTime()) {
                    this.timeError = 'Start time should be less than or equal Current time'
                } else {
                    this.timeError = ''
                }
        } else {
            let value = new Date(this.dateinputText).setHours(this.startHoursValue, this.startMin, 0, 0)
            this.dateinputText = new Date(value)
            this.showdateTime = this.dateinputText
            let hours = new Date(this.dateinputText).getHours();
            this.selectedTime = hours >= 12 ? 'pm' : 'am';
            let now = new Date();
            let d = new Date(this.dateinputText)
            if (now.getTime() < d.getTime()) {
                this.timeError = 'Start time should be less than or equal Current time'
            } else {
                this.timeError = ''
            }
        }
        
        

    },
    minusHour () {
        this.hideCurrent = false
        this.startHoursValue -= 1
        if (this.startHoursValue < 0) {
            this.startHoursValue = 23
        }

        this.startHour -= 1
        if (this.startHour === 0) {
            this.startHour = 12
        }
           
    },
    setAm () {
        this.hideCurrent = false
        if (this.selectedTime === 'am') {
            return
        }
        var hours = new Date(this.dateinputText).getHours();
        if (hours < 12) {
            this.startHoursValue = hours + 12
        }
        if (hours > 12) {
            this.startHoursValue = hours - 12
        }
        this.setFinalDateTime()
    },
    setPm () {
        this.hideCurrent = false
        if (this.selectedTime === 'pm') {
            return
        }
        var hours = new Date(this.dateinputText).getHours();
        if (hours < 12) {
            this.startHoursValue = hours + 12
        }
        if (hours > 12) {
            this.startHoursValue = hours - 12
        }
        this.setFinalDateTime()
    },
    addHour () {
        this.hideCurrent = false
        this.startHoursValue += 1
        if (this.startHoursValue > 23) {
          this.startHoursValue = 0
        }
        this.startHour += 1
        if (this.startHour > 12) {
          this.startHour = 1
        }
    },
    seMinutes (minutes) {
      switch (minutes) {
        case 15:
          this.startMin = minutes
          break
        case 30:
          this.startMin = minutes
          break
        case 45:
          this.startMin = minutes
          break
        case 0:
          this.startMin = minutes
          break
        default:
          this.startMin = 0
      }
      this.hideCurrent = false
    },
    decreaseHours () {
        this.hideCurrent = false
      if (this.hourInputValue > 0) {
        this.hourInputValue = Number(this.hourInputValue) - 1
      }
      this.setTimecall()
    },
    increaseHours () {
        this.hideCurrent = false
      this.hourInputValue = Number(this.hourInputValue) + 1
      this.setTimecall()
    },
    decreaseMin () {
        this.hideCurrent = false
      if (this.minuteInputValue > 0) {
        this.minuteInputValue = Number(this.minuteInputValue) - 15
      }
      this.setTimecall()
    },
    increaseMin () {
        this.hideCurrent = false
      this.minuteInputValue = Number(this.minuteInputValue) + 15
      this.setTimecall()
    },
    setTimecall () {
        this.hideCurrent = false
        this.dateinputText = ''
        this.showdateTime = ''
        if ((this.hourInputValue > 0 || this.minuteInputValue > 0) || (this.hourInputValue >= 0 || this.minuteInputValue > 0) || (this.hourInputValue > 0 || this.minuteInputValue >= 0) ) {
            let time = new Date(this.selectedMemberData.jobStartTime)
             let hrs  = new Date(time).getHours() + this.hourInputValue  
             let min  = new Date(time).getMinutes() + this.minuteInputValue   
            this.dateinputText = new Date(time).setHours(hrs, min, 0, 0)
            this.showdateTime = new Date(this.dateinputText)
            let startTime = moment(this.selectedMemberData.jobStartTime)
            let endTime = moment(this.dateinputText)
            this.spendHr = endTime.diff(startTime, 'hours')
            this.spendMin = endTime.diff(startTime, 'minutes') % 60
        }
        
    },
    forceJobStart () {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        MyJobApp.adminStartEndJobForUserWeb(
            this.forceStart ? 'startJob' : 'stopJob',
            this.visitDetail.visitId,
            this.selectedMemberData.userDetailId,
            new Date(this.dateinputText).toUTCString(),
            response => {
            document.body.style = 'overflow: visible;'
            this.$root.$emit('adminVisitStartStopHandler', true)
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
        )
    },
    updateDate () {
        this.hideCurrent = false
        this.showdateTime = this.dateinputText
    },
    setCurrentTime () {
        if (this.forceStart) {
            this.goToday = true
            var hours = new Date().getHours();
            var minutes = new Date().getMinutes();
            let dhour = hours % 12;
            this.startHour = dhour ? dhour : 12; // the hour '0' should be '12'
            this.startHoursValue = hours
            this.startMin = minutes
            this.setFinalDateTime(true)
            setTimeout(() => {
                this.hideCurrent = true
                this.goToday = false
            }, 100);
        } else {
            let time = new Date()
             let hrs  = new Date().getHours()
             let min  = new Date().getMinutes()  
            this.dateinputText = new Date(time).setHours(hrs, min, 0, 0)
            this.showdateTime = new Date(this.dateinputText)
            let startTime = moment(this.selectedMemberData.jobStartTime)
            let endTime = moment(this.dateinputText)
            this.spendHr = endTime.diff(startTime, 'hours')
            this.hourInputValue = this.spendHr
            this.spendMin = endTime.diff(startTime, 'minutes') % 60
            this.minuteInputValue = this.spendMin
        }
    },
    discard () {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('adminVisitStartStopHandler', false)
    },
  },
}
</script>
<style scoped>

</style>