<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <div class="popup_overlay">
    <div class="custom_dialog" :style="`width: 100%; overflow: auto; height:100vh;`">
      <div class="relative">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
                Location
            </div>
            <div class="flex items-center">
              <div class=" text-primary heading-3 cursor-pointer bg-white px-2 py-1 rounded-xl" @click="isFilterOpen=true">
                <i class="fas fa-filter"></i>
              </div>
              <div class=" text-primary heading-3 cursor-pointer bg-white px-2.5 py-1 rounded-xl ml-3" @click="discard(false)">
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>
          <div class="overflow-auto" style="height: 92vh">
            <div class="relative p-2" v-if="showMap">
              <GmapMap ref="map" :center="center" :zoom="21" style="width: 100%; height: 800px">
                  <GmapMarker :position="center" :clickable="true">
                    <gmap-custom-marker v-if="marker.isShow" v-for="(marker, index) in userLocation" :key="'1' + index"
                    :marker="{latitude: marker.lastLocation.latitude, longitude: marker.lastLocation.longitude}">
                      <div class="staff-popup card rounded bg-white">
                        <p class="text-text2 heading-6">{{marker.firstName}} {{marker.lastName}}</p>
                        <p class="text-text2" v-if="marker.lastLocation.addedDate !== ''">{{marker.lastLocation.addedDate | filterForLocationTime}}</p>
                        <p class="text-text2" v-else>-</p>
                      </div>
                    </gmap-custom-marker>
                  </GmapMarker>
                </GmapMap>
              <!-- <gmap-map
              ref="map"
                :center="center"
                :zoom="11"
                style="width: 100%; height: 90vh">
                <gmap-marker :position="center" :clickable="true">
                  <gmap-custom-marker v-if="marker.isShow" v-for="(marker, index) in userLocation" :key="'1' + index"
                  :marker="{latitude: marker.lastLocation.latitude, longitude: marker.lastLocation.longitude}">
                    <div class="staff-popup card rounded bg-white">
                      <p class="text-text2 heading-6">{{marker.firstName}} {{marker.lastName}}</p>
                      <p class="text-text2" v-if="marker.lastLocation.addedDate !== ''">{{marker.lastLocation.addedDate | filterForLocationTime}}</p>
                      <p class="text-text2" v-else>-</p>
                    </div>
                  </gmap-custom-marker>
                  <gmap-polyline v-for="(data, index) in locationArr" :key="'2' + index" :path="data" ref="polyline"
                  :options="{ strokeColor: data.displayColor}"
                  ></gmap-polyline>
                </gmap-marker>
              </gmap-map> -->
            </div>
            <div class="absolute filterCss card bg-white rounded shadow-xl transition-transform delay-300 ease-in-out transition-2" v-if="isFilterOpen" >
              <div class="sticky top-0">
                <div class="bg-gray4 flex justify-between p-3">
                  <div class=" font-medium text-white heading-3">
                    Filter
                  </div>
                </div>
              </div>
              <div  class="h-full overflow-auto">
                    <div class="p-2">
                      <div class="card bg-white rounded-xl p-2 mb-4" v-for="(data, index) in userLocation " :key="'3' + index">
                        <div class="text-text2 heading-4 flex items-center justify-between p-1">
                          <span class="flex items-center cursor-pointer">
                            <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary" @click="checkboxCheck(data, index)" v-model="data.isSelected" >
                            <!-- <CheckBoxInput :checkValue="data.isSelected" :label="``" @setIsActive="data.isSelected = !data.isSelected"  /> -->
                            <span class="pl-2 cursor-pointer" @click="checkboxCheck(data, index)">{{data.firstName}} {{data.lastName}}</span>
                            <div :style="`background: ${data.displayColor}`"  class=" rounded-full h-3 w-3 ml-1"></div>
                          </span>
                          <div @click="data.isOpen = !data.isOpen" class="pl-2 text-primary cursor-pointer heading-4 flex items-center">
                            <span>{{data.totalMinits | TimeIntoString}}</span>
                            <span v-if="!data.isOpen" class="text-primary pl-2"><i class="fas fa-chevron-down"></i></span>
                            <div v-if="data.isOpen" class="text-primary pl-2"><i class="fas fa-chevron-up"></i></div>
                          </div>
                        </div>
                        <div class="" v-if="data.totalMinits > 0 && data.isOpen">
                          <div class="bg-white card rounded p-2 m-4 mt-0 mb-1" v-for="(subData, subIndex) in data.timeSheetList " :key="'4' + subIndex">
                            <div class="flex items-center justify-between">
                              <span class="flex items-center">
                                <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary" @click="subCheckboxCheck(subData, data, subIndex, index)" v-model="subData.isSubSelcted">
                                <!-- <CheckBoxInput :checkValue="subData.isSubSelcted" :label="``" @setIsActive="subData.isSubSelcted = !data.isSubSelcted"  /> -->
                                <div class="text-right text-gray4 heading-5 pl-2 cursor-pointer" @click="subCheckboxCheck(subData, data, subIndex, index)">{{ subData.displayText}}</div>
                              </span>
                              <p class="text-text2">{{subData.totalMint | ConvertMinutesInHoursAndMinutes}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
              </div>
              <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
                <div class="text-rigth flex gap-2 mb-3">
                    <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Close'" @buttonAction="closePopup()"/>
                    <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Apply'" @buttonAction="applyFilter()"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  </div>
</template>
<script>
/* global google */
import {getDisplayText} from '@/utils/convertDateAndTime.js'
import {toRaw} from 'vue';
import {gmapApi} from 'vue2-google-maps'
var moment = require('moment')
// import CheckBoxInput from '@/View/components/checkBoxInput.vue'
import GmapCustomMarker from '../../../setting/location/vue2-gmap-custom-marker/gmap-custom-marker.vue'
import store from '@/store'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import MyJobApp from '@/api/MyJobApp.js'
import Button from '@/View/components/globalButton.vue'
import { mapState } from 'vuex'
Vue.use(VueGoogleMaps, {
  load: {
    key: store.getters.googleMapApiKey,
    libraries: 'places,drawing' // necessary for places input
  }
})
export default {
  props: ['markers', 'visitDetail'],
  name: 'example',
  omputed: mapState({
      google: gmapApi
  }),
  components: {
    'gmap-custom-marker': GmapCustomMarker,
    // CheckBoxInput,
    Button,
  },
  data () {
    return {
      pathMarker: [],
      flightPath: [],
      filterArr: [],
      isFilterOpen: false,
      locationArr: [],
      userLocation: [],
      selectedEmpId: [],
      center: null,
      // markers: [],
      places: [],
      showMap: false,
      currentPlace: null
    }
  },
  watch: {
    userLocation: {
      handler () {
        // console.log('this.userLocation watch---------->', this.userLocation)
        // for (let index = 0; index < this.userLocation.length; index++) {
        //   for (let JJ = 0; JJ < this.userLocation[index].timeSheetList.length; JJ++) {
        //     if (this.userLocation[index].isSelected) {
        //       this.userLocation[index].timeSheetList[JJ].isSubSelcted = true
        //     } else {
        //       this.userLocation[index].timeSheetList[JJ].isSubSelcted = false
        //     }
        //   }
        // }
      },
      deep: true
    }
  },
  mounted () {
    this.getLocationdetail()
    document.body.style = 'overflow: hidden;'
  },
  methods: {
    checkboxCheck (data, index) {
      if (this.userLocation[index].isSelected === false) {
        this.userLocation[index].isSelected = true
      } else {
        this.userLocation[index].isSelected = false
      }
      if (data.isSelected === false) {
        data.timeSheetList.forEach(element => {
          element.isSubSelcted = false
        });
      } else if (data.isSelected === true) {
        data.timeSheetList.forEach(element => {
          element.isSubSelcted = true
        });
      }
    },
    subCheckboxCheck (subData, data, subIndex, index) {
      console.log('checkboxCheck 2',subData, data, subIndex, index, subData.isSubSelcted)
      if (data.isSelected === true) {
        let countA = 0
        data.timeSheetList.forEach(element => {
          if (element.jobTimeTrackingId === subData.jobTimeTrackingId) {
            subData.isSubSelcted = true
          } else {
            subData.isSubSelcted = false
          }
          if (element.isSubSelcted) {
            countA ++
          } else {
            countA --
          }
          // data.isSelected = false
        });
        if (countA === 0) {
          data.isSelected = false
        }
      } else if (data.isSelected === false) {
        data.isSelected = true
        subData.isSubSelcted = true
        let count = 0
        data.timeSheetList.forEach(element => {
          if (element.isSubSelcted) {
            count ++
          } else {
            count --
          }
        })
        if (count === data.timeSheetList.length) {
          data.isSelected = true
        }
      }
      
    },
    closePopup () {
      this.isFilterOpen = false
    },
    applyFilter () {
      // this.pathMarker.setMap(null)
      // this.flightPath.setMap(null)
      this.pathMarker.map((marker) => toRaw(marker).setMap(null))
      this.flightPath.map((marker) => toRaw(marker).setMap(null))

      // this.$refs.map.$mapPromise.then(() => {
      //   let trafficLayer = new google.maps.Polyline;
      //   trafficLayer.setVisible(false)
      // });

      var infowindow = new google.maps.InfoWindow()
      let map = this.$refs.map.$mapObject
      let tempArr = []
      for (let i = 0; i < this.userLocation.length; i++) {
        if (this.userLocation[i].isSelected === true) {
          this.userLocation[i].isShow = true
        let list = this.userLocation[i].timeSheetList
        for (let J = 0; J < list.length; J++) {
          if (list[J].isSubSelcted) {
            for (let K = 0; K < list[J].locationList.length; K++) {
              var tempObj = {
                lat: parseFloat(list[J].locationList[K].latitude),
                lng: parseFloat(list[J].locationList[K].longitude)
              }
              tempArr.push(tempObj)
              let mapMarker = new google.maps.Marker({
                position: new google.maps.LatLng(list[J].locationList[K].latitude,
                  list[J].locationList[K].longitude),
                icon: {
                  path: google.maps.SymbolPath.CIRCLE,
                  scale: 4,
                  fillColor: this.userLocation[i].displayColor,
                  fillOpacity: 0.8,
                  strokeWeight: 0.2
                },
                map: map
              })
              let test = moment(list[J].locationList[K].addedDate).format('Y-M-D hh:mm:ss a')
              google.maps.event.addListener(mapMarker, 'mouseover', (function (mapMarker) {
                return function () {
                  infowindow.setContent(test.toString())
                  infowindow.open(map, mapMarker)
                }
              })(mapMarker, i))
              google.maps.event.addListener(mapMarker, 'mouseout', (function () {
                return function () {
                  infowindow.close()
                }
              })(mapMarker, i))
              this.pathMarker.push(mapMarker)
              let temp123 = new google.maps.Polyline({
                map: map,
                path: tempArr,
                strokeColor: this.userLocation[i].displayColor,
                strokeOpacity: 0.9,
                strokeWeight: 3
              })
              this.flightPath.push(temp123)
            }
          }
        }
      } else {
        this.userLocation[i].isShow = false
      }
    }
    this.setBound()
    this.isFilterOpen = false
    },
    closeList () {
      this.isFilterOpen = false
    },
    discard () {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('locationViewHandler', false)
    },
    getLocationdetail(){
      this.showMap = false
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
      MyJobApp.getJobLocation(
        this.visitDetail.visitId,
        response => {
          if (response.Data !== null) {
            this.locationArr = []
            this.center = { lat: response.Data[0].organizationAddress.latitude, lng: response.Data[0].organizationAddress.longitude}
            let userLocation = response.Data !== null ? response.Data : []
            for (let index = 0; index < userLocation.length; index++) {
              userLocation[index].isShow = true
              userLocation[index].isOpen = true
              userLocation[index].isSelected = true
              for (let JJ = 0; JJ < userLocation[index].timeSheetList.length; JJ++) {
                userLocation[index].timeSheetList[JJ].isSubSelcted = true
                userLocation[index].timeSheetList[JJ].isSubOpen = true
                let subTotaltime = 0
                if (userLocation[index].timeSheetList[JJ].startTime !== '' && userLocation[index].timeSheetList[JJ].endTime !== '') {
                  userLocation[index].timeSheetList[JJ].displayText = getDisplayText(userLocation[index].timeSheetList[JJ].startTime, userLocation[index].timeSheetList[JJ].endTime)
                  let startTime = moment(userLocation[index].timeSheetList[JJ].startTime)
                  let endTime = moment(userLocation[index].timeSheetList[JJ].endTime)
                  let totalMin = endTime.diff(startTime, 'minutes')
                  subTotaltime += totalMin
                  userLocation[index].timeSheetList[JJ].totalMint = totalMin
                  userLocation[index].totalMinits = subTotaltime
                } else {
                  userLocation[index].timeSheetList[JJ].displayText = '--'
                }
              }
            }
            this.userLocation = userLocation
            this.filterArr = this.userLocation
            let data = []
            this.userLocation.forEach(element => {
              element.showLocation = true;
              element.showLastLocation = false;
              element.totalLoggedTime = 0;
              element.openDetail = true;
              // console.log('111 000');

              for (let index = 0; index < element.timeSheetList.length; index++) {
                // console.log('111 111');

                // const ind = Math.floor(
                //   Math.random() * (colorList.length - 1 - 1) + 1,
                // );
                element.timeSheetList[index].displayColor = element.displayColor;
                element.timeSheetList[index].showPoly = element.timeSheetList[index].locationList.length > 0;
                if (element.timeSheetList[index].endTime === '') {
                  element.showLastLocation = true;
                }

                const startDate = new Date(element.timeSheetList[index].startTime);

                const endTime =
                  element.timeSheetList[index].endTime !== ''
                    ? new Date(element.timeSheetList[index].endTime)
                    : new Date();

                const diff = Math.abs(endTime.getTime() - startDate.getTime());
                const minutes = diff / 1000 / 60;
                element.totalLoggedTime += minutes;
                // console.log('111 222', element.timeSheetList[index].locationList);

                if (element.timeSheetList[index].locationList[0]) {
                  const data = JSON.parse(
                    element.timeSheetList[index].locationList[0].locationLogs,
                  );

                  // console.log('111 333', data);

                  element.timeSheetList[index].deviceName =
                    element.timeSheetList[index]?.locationList[0]?.deviceModel;
                  element.timeSheetList[index].locationList = data.locationLogs;
                  this.locationArr.push(element.timeSheetList[index]);
                  // console.log('111 *******');
                }
              }
            });
            // for (let index = 0; index < this.userLocation.length; index++) {
            //   let list = this.userLocation[index].timeSheetList
            //   for (let i = 0; i < list.length; i++) {
            //     for (let J = 0; J < list[i].locationList.length; J++) {
            //       let latitude = parseFloat(list[i].locationList[J].latitude)
            //       let longitude = parseFloat(list[i].locationList[J].longitude)
            //       data.push({lat: latitude, lng: longitude, displayColor: this.userLocation[index].displayColor})
            //     }
            //   }
            // }
            this.locationArr.push(data)
            // setTimeout(() => {
            //   this.setBound()
            // }, 100)
          } else {  
            let orgDetail = JSON.parse(localStorage.getItem('orgInfo'))
            this.center = { lat: orgDetail.latitude, lng: orgDetail.longitude}
          }
          // console.log('this.locationArr', this.locationArr)
          this.showMap = true
          setTimeout(() => {
            this.setBound()
            this.displayJobTrackingPathTechnicion()
          }, 1200);
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.showMap = true
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        })
      
    },
    displayJobTrackingPathTechnicion () {
      // let dateForInfoWindow = []
      
      var infowindow = new google.maps.InfoWindow()
      let map = this.$refs.map.$mapObject
      let tempArr = []
      // let pathMarker = []
      for (let i = 0; i < this.locationArr.length; i++) {
        // console.log('(this.locationArr[i].length ', this.locationArr[i].length )
        if (this.locationArr[i].locationList !== undefined) {
          // console.log('this.locationArr2', this.locationArr[i])
          let list = this.locationArr[i].locationList
          // console.log('list[list]', list)
          for (let J = 0; J < list.length; J++) {
            // console.log('list[J]', list[J])
              var tempObj = {
                lat: parseFloat(list[J].latitude),
                lng: parseFloat(list[J].longitude)
              }
              tempArr.push(tempObj)
              let mapMarker = new google.maps.Marker({
                position: new google.maps.LatLng(
                  list[J].latitude, list[J].longitude),
                  icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 4,
                    fillColor: this.locationArr[i].displayColor,
                    fillOpacity: 0.8,
                    strokeWeight: 0.2
                  },
                  map: map
                })
              let test = moment(list[J].addedDate).format('Y-M-D hh:mm:ss a')
              google.maps.event.addListener(mapMarker, 'mouseover', (function (mapMarker) {
                return function () {
                  infowindow.setContent(test.toString())
                  infowindow.open(map, mapMarker)
                }
              })(mapMarker, i))
              google.maps.event.addListener(mapMarker, 'mouseout', (function () {
                return function () {
                  infowindow.close()
                }
              })(mapMarker, i))
              this.pathMarker.push(mapMarker)
              // list[J].pathMarker = pathMarker
                let temp123 = new google.maps.Polyline({
                  map: map,
                  path: tempArr,
                  strokeColor: this.locationArr[i].displayColor,
                  strokeOpacity: 0.9,
                  strokeWeight: 3
                })
                this.flightPath.push(temp123)
                // flightPath.setMap(null);
            }
            // console.log('...mark LOCAITON....', this.locationArr)
        }
      }
      this.setBound()
    },
    setBound () {
      this.$refs.map.$mapPromise.then((map) => {
        let fitBoud = false
        const bounds = new google.maps.LatLngBounds()
        for (let m of this.locationArr) {
          for (let index = 0; index < m.length; index++) {
            fitBoud = true
            let position = {lat: m[index].lat, lng: m[index].lng}
            bounds.extend(position)
          }
        }
        for (let A of this.userLocation) {
          for (let J = 0; J < A.length; J++) {
            fitBoud = true
            let position = {lat: A[J].lastLocation.latitude, lng: A[J].lastLocation.latitude}
            bounds.extend(position)
          }
        }

        fitBoud = true
        let position = {lat: this.center.lat, lng: this.center.lng}
        bounds.extend(position)

        if (fitBoud === true) {
          map.fitBounds(bounds)
        }
      })
    },
  },
  beforeDestroy () {}
}
</script>
<style scoped>
.imageFullScreen {
  width: auto;
  max-height: 100%;
  justify-content: center;
  align-items: center;
}
.staff-popup {
    -webkit-transition: none!important;
    transition: none!important;
    color: #000;
    background-color: #ffffff;
    border-color: #626f80;
    width: 160px;
    min-width: 38px;
    border-radius: 12px;
    padding: 4px 8px 4px 12px;
    position: absolute;
    border-width: 2px;
    border-style: solid;
    height: auto;
    -webkit-transition: .3s all cubic-bezier(.39,.575,.565,1);
    transition: .3s all cubic-bezier(.39,.575,.565,1);
    font-size: 13px;
    text-align: center;
    left: -58px;
    top: -42px;
}
.staff-popup img {
    height: 35px;
    border-radius: 50%;
}
.staff-popup:before {
  background-color: inherit;
  border-color: inherit;
  display: inline-block;
  margin: 0;
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 36px;
  left: calc(50% - 5px);
  border-width: 0 2px 2px 0;
  border-style: solid;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.filterCss {
  width: 600px;
  position: absolute;
  top: 58px;
  right: 0;
  overflow: auto;
  height: 90vh;
}
</style>