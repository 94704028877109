<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 1000px; overflow: visible; max-height: 85%;">
      <div class="relative">
        <div class="">
          <div class="bg-primary flex justify-between p-3 rounded-t-xl">
            <div class=" font-medium text-white heading-3">
                Schedule Visit
            </div>
          </div>
        </div>
        <div class="p-4 md:grid gap-4 grid-cols-2">
            <div class="flex justify-center">
            <DateTimepicker
                v-model="visitStartDateTime"
                :title="''"
                :showOnlyDate="true"
                :hideGoToday="true"
                :hideBtn="true"
                :roundedCard="true"
                @input="updateDate()"
                >
            </DateTimepicker>
            </div>
            <div>
                <div class="w-full mb-4">
                <div v-if="!isAnyTime" class="mb-4 w-full">
                    <TimePicker :title="'Start Time'" v-model="startTime"/>
                    
                </div>
                <div v-if="!isAnyTime" class="mb-4 w-full">
                    <TimePicker :title="'End Time'" v-model="endTime"/>
                </div>
                <div class="w-full">
                    <div v-if="isAnyTime" class="card bg-gray1 items-center rounded-xl p-4  w-full flex gap-2 text-text1  mb-4">
                    <div class="flex justify-between gap-2 items-center">
                    <p>Time:</p>
                    <div class="font-semibold heading-4 w-full">
                        Anytime
                    </div>
                    </div>
                </div>
                <p v-if="timeErr" class="text-error mb-2">End time should be greater than Start time</p>
                <div class="flex justify-end">
                    <p v-if="!isAnyTime" @click="isAnyTime = true" class="text-primary cursor-pointer">Clear Time</p>
                    <p v-else @click="isAnyTime = !isAnyTime" class="text-primary cursor-pointer">Set Time</p>
                </div>
                </div>
                </div>
            </div>
        </div>
        <div class=" sticky bottom-0 bg-white w-full rounded-b-xl flex justify-end pr-3">
        <div class="text-rigth flex gap-2 mb-3">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveSchedule()"/>
        </div>
        </div>
        </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import DateTimepicker from '../../../components/dateTimepicker.vue'
import TimePicker from '../../components/timePicker.vue'
import moment from 'moment'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  name: 'sendEmail',
  components: {
    Button,
    DateTimepicker,
    TimePicker,
  },
  data () {
    return {
        visitStartDateTime: '',
        isAnyTime: false,
        startTime: '',
        endTime: '',
        timeErr: false,
    }
  },
  props: ["visitDetail"],
   created() {
  },
  beforeMount () {
    this.visitStartDateTime = moment(new Date(this.visitDetail.visitStartDateTime)).format('YYYY-MM-DD')
    this.setTimes(this.visitDetail.visitStartDateTime, this.visitDetail.visitEndDateTime)
  },
  mounted () {
    this.$root.$on('finalvisitlistHandler', (response) => {
      console.log('response', response)
    })
  },
  beforeDestroy () {
    this.$root.$off("finalvisitlistHandler");
  },
  watch: {
    isAnyTime: {
      handler () {
        if (this.isAnyTime) {
             this.startTime = '00:00'
             this.endTime = '23:59'
        } else {
            let hours = new Date().getHours()
            let min = new Date().getMinutes()
            hours = min > 45 &&  min < 60 ? hours + 1 : hours
            let minutes = min <= 15 ? 15 : min <= 30 ? 30 : min <= 45 ? 45 : min <= 60 ? 0 : 0
            
            this.startTime = hours + ':' + minutes
            this.endTime = hours + 2 + ':' + minutes
        }
      }
    },
    startTime: {
      handler () {
        this.timeErr = false
           let x = this.startTime.split(':')
            let hour = parseInt(x[0])
            let min = parseInt(x[1])
           let y = this.endTime.split(':')
            let hr2 = parseInt(y[0])
            let min2 = parseInt(y[1])
        if (hour > hr2) {
          this.timeErr = true
        } else if (hour === hr2 && (min > min2 || min === min2) ) {
          this.timeErr = true
        }
      }
    },
    endTime: {
      handler () {
        this.timeErr = false
        let x = this.startTime.split(':')
            let hour = parseInt(x[0])
            let min = parseInt(x[1])
           let y = this.endTime.split(':')
            let hr2 = parseInt(y[0])
            let min2 = parseInt(y[1])
        if (hour > hr2) {
          this.timeErr = true
        } else if (hour === hr2 && (min > min2 || min === min2) ) {
          this.timeErr = true
        }
      }
    }
  },
  methods: {
    setTimes (start, end) {
      let hours = new Date(start).getHours()
        let min = new Date(start).getMinutes() 
        this.startTime = hours + ':' + min

        let hours2 = new Date(end).getHours()
        let min2 = new Date(end).getMinutes()
        this.endTime = hours2 + ':' + min2
    },
    discard () {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('sheduleUpdateHandler', false)
    },
    saveSchedule () {
        let isValid = true
        if (this.timeErr) {
            isValid = false
        }
        let temp = moment(this.visitStartDateTime).format('YYYY-MM-DD')
            let strDate = temp + ' ' + this.startTime
            let endDate = temp + ' ' + this.endTime
            console.log("DATA.START", strDate);
            console.log("DATA.end", endDate);
        let obj = {
            visitId: this.visitDetail.visitId,
            visitStartDateTime: new Date(strDate).toUTCString(),
            visitEndDateTime: new Date(endDate).toUTCString(),
        }
        console.log("obj", obj);
      if (isValid) {

        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        MyJobApp.updateVisitTime(
            obj,
            response => {
            document.body.style = 'overflow: visible;'
            this.$root.$emit('sheduleUpdateHandler', true)
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
        )
      }
    }
  },
}
</script>
<style scoped>

</style>