<template>
  <div class="popup_overlay px-4">
  <div class="custom_dialog rounded-xl" id="itemdialogs" style="width: 800px;max-height: 85%;">
    <div class="relative bg-screen_bg">
      <div class="bg-primary flex justify-between p-3 sticky top-0">
        <div class=" font-medium text-white heading-3">
            Check List
        </div>
      </div>
      <div style="max-height: 65vh" class="overflow-auto">
        <!-- <div class="px-4 pt-4">
          <div class="w-72">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search...`"/>
          </div>
        </div> -->
        <div v-if="itemsDataList.length > 0" class="p-4">
          <div v-for="(item, i) in itemsDataList" :key="i + 'i'" class="card rounded-xl bg-white mb-2 p-4">
            <div class="flex items-center ">
              <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary" @click="checkboxCheck(item)" v-model="item.isSelected" >
              <span class="pl-4 text-text2 heading-4 cursor-pointer" @click="checkboxCheck(item)">{{item.checkListTitle}}</span>
            </div>
          </div>
        </div>
        <div v-else class="px-4 pb-4">
          <NoRecords :alertMessage="`No records found.`" />
        </div>
      </div>
        <div class=" sticky bottom-1 bg-screen_bg w-full flex justify-end pr-3 pt-3">
          <div class="text-rigth flex gap-2 mb-3">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="discard()"/>
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveItems()"/>
          </div>
          </div>
      </div>
  </div>
</div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
// import SearchTextInput from '@/View/components/searchTextInput.vue'
import NoRecords from '@/View/components/noRecords.vue'
// import TextField from '@/View/components/textfield.vue'
// import TextAreaInput from '@/View/components/textAreaInput.vue'
import deboucneMixin from '@/mixins/debounce.js'
import MyJobApp from '@/api/MyJobApp.js'
export default {
name: 'sendEmail',
components: {
  NoRecords,
  Button,
  // SearchTextInput,
},
mixins: [deboucneMixin],
data () {
  return {
    selectedItemList: [],
    itemsDataList: [],
    searchItemText: '',
    dialogHeight: '',
    getItems: () => {},
    deletedArray: [],
    selectNewArray: [],
  }
},
props: ["itemsSelected", "object"],
 created() {
  this.selectedItemList = JSON.parse(JSON.stringify(this.itemsSelected)) 
},
mounted () {
  document.body.style = 'overflow: hidden;'
  this.itemListGet()
  this.getItems = this.debounce(function () {
    this.itemListGet()
  }, 500)
  this.dialogHeight = document.getElementById('itemdialogs').offsetHeight;
},
watch: {
  deletedArray: {
    handler () {
      console.log('deletedArray', this.deletedArray)
    },
  },
  selectNewArray: {
    handler () {
      console.log('selectNewArray', this.selectNewArray)
    },
  }
},
methods: {
  checkboxCheck (data) {
    if (data.isOldSelected) {
      let fIndex = this.deletedArray.findIndex(x => x.checkListItemMasterId === data.checkListItemMasterId)
      console.log('fIndex', fIndex)
      if (fIndex < 0) {
        this.deletedArray.push(data)
      } else {
        for (let index = 0; index < this.deletedArray.length; index++) {
          if (this.deletedArray[index].checkListItemMasterId === data.checkListItemMasterId) {
            this.deletedArray.splice(index, 1)
          }
        }
      }
    }
    if (!data.isOldSelected) {
      let fIndex = this.selectNewArray.findIndex(x => x.checkListItemMasterId === data.checkListItemMasterId)
      console.log('fIndex', fIndex)
      if (fIndex < 0) {
        this.selectNewArray.push(data)
      } else {
        for (let index = 0; index < this.selectNewArray.length; index++) {
          if (this.selectNewArray[index].checkListItemMasterId === data.checkListItemMasterId) {
            this.selectNewArray.splice(index, 1)
          }
        }
      }
    }
    data.isSelected = !data.isSelected
  },
   searchCall (data) {
    this.searchItemText = data
    this.getItems()
  },
  discard () {
    this.$root.$emit('itemSelectionHandler2', false)
  },
  saveItems () {
    console.log('DELETE ARRRAYYYYYYYYY:::::::::::::-------->>>>>>>>>>>>>>>>', this.deletedArray)
    console.log('ADDED ARRRAYYYYYYYYY:::::::::::::-------->>>>>>>>>>>>>>>>', this.selectNewArray)
    let tempArr = {
      visitId: this.object.visitId,
      jobId: this.object.jobId,
      checkList: this.selectNewArray,
      deletedCheckList: this.deletedArray
    }
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
    MyJobApp.AddDeleteCheckListInVisit(
      tempArr,
      response => {
        this.$root.$emit('itemSelectionHandler2', true)
        this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      },
      (err) => {
        this.$store.dispatch('SetLoader', {status: false, message: ''})
        this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
      }
    )
  },
  itemListGet () {
    MyJobApp.SettingCheckListItemList(
      0,
      50,
      this.searchItemText,
      '',
      'DESC',
      true,
      0,
      response => {
        let tempList = response.Data.tableRow !== null ? response.Data.tableRow : []
        tempList.forEach(element => {
          element.isOldSelected = false
          element.isSelected = false
        });
        setTimeout(() => {
          if (this.selectedItemList.length > 0 && tempList.length > 0) {
          this.selectedItemList.map(element => {
            let fIndex = tempList.findIndex(x => x.checkListItemMasterId === element.checkListItemMasterId)
              if (fIndex >= 0) {
                tempList[fIndex].isSelected = true
                tempList[fIndex].isOldSelected = true
              }
            });
          }
        }, 50);
        this.itemsDataList = tempList
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      },
      () => {
        this.resetPaginationValues = false
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
    )
  }
},
beforeDestroy () {
  // this.$root.$off('closeProfileDialog')
}
}
</script>
<style scoped>

</style>