<template>
  <div class="popup_overlay px-4">
      <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 90%;" v-if="canVisitSignature">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between items-center p-3">
              <div class=" font-medium text-white heading-3">
                Add Signature <span class="heading-6">(Optional)</span>
              </div>
              <div class="flex items-center">
                <span class=" font-bold text-white heading-6 cursor-pointer" @click="undoSign()">
                  <i class="fa-solid fa-rotate-left"></i>Undo
                </span>
                <span class=" font-bold text-white heading-6 cursor-pointer pl-4" @click="toDataURL()">
                  Reset
                </span>
              </div>
            </div>
            
            <div class="m-4 text-text2 border border-dashed border-text2 rounded" style="cursor: crosshair;">
              <VueSignaturePad style="background-color:#F3F3F3"
                id="signature"
                height="200px"
                ref="signaturePad"
                :options="strokeOptions"
              />
            </div>
            <div class="flex justify-end p-3">
              <div class="text-rigth flex gap-2">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="closePopup(false)"/>
                <Button v-if="visitDetail.totalVisits === 1" :btnSize="'medium'" :textColor="'white'" :btnColor="'secondary'" :btnText="'Save and Close Job'" @buttonAction="saveSignature(true)"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Complete'" @buttonAction="saveSignature(false)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom_dialog rounded-xl" style="width: 600px; overflow: auto; max-height: 90%;" v-else>
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between items-center p-3">
              <div class=" font-medium text-white heading-3">
                Complete Visit
              </div>
            </div>
            <div  class="py-4 px-2 font-semibold">
              <p class="heading-3 text-text1">Are you sure want to complete visit?</p>
            </div>
            <div class="flex justify-end p-3">
              <div class="text-rigth flex gap-2">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="closePopup(false)"/>
                <Button v-if="visitDetail.totalVisits === 1" :btnSize="'medium'" :textColor="'white'" :btnColor="'secondary'" :btnText="'Save and Close Job'" @buttonAction="saveSignature(true)"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Complete'" @buttonAction="saveSignature(false)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import {FilterPermissions} from '@/utils/Permissions.js'
import MyJobApp from "@/api/MyJobApp.js";
// import VPerfectSignature from 'v-perfect-signature'
import Button from '@/View/components/globalButton.vue'
export default {
  props: ['visitDetail', 'checkListArr'],
  components: {
    Button,
    // VPerfectSignature
  },
  data() {
    return {
      canVisitSignature: FilterPermissions('visit_sign'),
      dataURL: '',
      strokeOptions: {
        penColor: '#000',
        size: 6,
        thinning: 0.7,
        smoothing: 0.5,
        streamline: 0.5
      },
    };
  },
  mounted() {
    console.log('visitDetail', this.visitDetail)
    console.log('checkListArr----->', this.checkListArr)
  },
  watch: {
  },
  beforeDestroy() {
  },
  methods: {
    saveSignature (value) {
      let sendArray = this.checkListArr
      sendArray.forEach(element => {
        if (element.isSelected) {
          element.isItemChecked = true
        } else {
          element.isItemChecked = false
        }
      });
      console.log('sendArray' ,sendArray)
      if (this.canVisitSignature) {
        this.dataURL = this.$refs.signaturePad.saveSignature()
        if (this.dataURL.data === undefined || this.dataURL.isEmpty === true) {
          this.dataURL.data = ''
        }
        this.$store.dispatch("SetLoader", { status: true, message: "Loading..." })
        MyJobApp.visitComplete(
          'close',
          '',
          parseInt(this.$route.params.visitId),
          sendArray,
          this.dataURL.data,
          value,
          (response) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch("SetLoader", { status: false, message: "" });
            this.$root.$emit('completeVisitHandler', true)
          },
          (error) => {
            this.$store.dispatch("SetAlert", { status: true, message: error.message, color: 'error'});
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        )
      } else {
        this.$store.dispatch("SetLoader", { status: true, message: "Loading..." })
        MyJobApp.visitComplete(
          'close',
          '',
          parseInt(this.$route.params.visitId),
          sendArray,
          '',
          value,
          (response) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch("SetLoader", { status: false, message: "" });
            this.$root.$emit('completeVisitHandler', true)
          },
          (error) => {
            this.$store.dispatch("SetAlert", { status: true, message: error.message, color: 'error'});
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        )
      }
    },
    onEnd() {
      console.log('on end')
    },
    undoSign () {
      this.$refs.signaturePad.undoSignature();
    },
    toDataURL() {
      this.$refs.signaturePad.clearSignature()
    },
    closePopup () {
      this.$root.$emit('completeVisitHandler', false)
    },
  }
};
</script>
<style scoped>
.quote_name_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
#sig-canvas {
  border: 2px dotted #CCCCCC;
  border-radius: 15px;
  cursor: crosshair;
}
</style>