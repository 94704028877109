<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 600px; overflow: visible; max-height: 85%;">
      <div class="relative">
        <div class="">
          <div class="bg-primary flex justify-between p-3 rounded-t-xl">
            <div class=" font-medium text-white heading-3">
                Update Team
            </div>
          </div>
          <div class="p-4">
            <div class="mb-4">
                <MultiSelect 
                :inputext="employeeSearchText" 
                :inputId="'empslectinpuid'"
                :lableText="'Assign to'"
                :placholderText="`Select Employee`"
                :autoFocus="false"
                :showArrow="true"
                :setReadOnly="false"
                :searchCallApi="false"
                :showPlus="false"
                :keyName="'fullName'"
                :idKey="'userDetailId'"
                :sectionId="'employeelistsecid'"
                :listId="'employeelistidb'"
                :items="employeeList"
                :selectedValues="selectedEmployee"
                @selectNewForVal="selectEmployee"
                />
            </div>
          </div>
        </div>
          <div class=" sticky bottom-0 bg-white w-full rounded-b-xl flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveAssignee()"/>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import MultiSelect from '@/View/components/multipleSelectDrop.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  name: 'sendEmail',
  components: {
    Button,
    MultiSelect,
  },
  data () {
    return {
        employeeSearchText: '',
        selectedEmployee: [],
        employeeList: [],
    }
  },
  props: ["selectedTeam", "jobId"],
   created() {
  },
  mounted () {
    if (this.selectedTeam.length > 0) {
      this.selectedEmployee = []
       this.selectedTeam.map(el => {
        this.selectedEmployee.push({...el, fullName: el.firstName + ' ' + el.lastName})
    })  
    }
    this.getEmployeeList()
  },
  beforeDestroy () {
    // this.$root.$off("setItemSelectedHandler");
  },
  watch: {},
  methods: {
    selectEmployee (emplist) {
      this.selectedEmployee = []
      let tempEmpList = []
      emplist.map((data) => {
        let obj = {
          userDetailId: data.userDetailId,
          fullName: data.fullName
        }
        tempEmpList.push({userDetailId:data.userDetailId})
        this.selectedEmployee.push(obj)
      })
    },
    discard () {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('addUpdateTeamHandler', false)
    },
    getEmployeeList () {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let tempList = []
          if (response.Data.tableRow !== null) {
            response.Data.tableRow.map(el => {
               tempList.push({...el, fullName: el.firstName + ' ' + el.lastName})
            })
          }
          this.employeeList = tempList
          if (this.selectedTeam.length > 0) {
            console.log("ccccccccccc", this.selectedTeam);
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    saveAssignee () {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        let final = []
        this.selectedEmployee.map(data => {
            final.push({userDetailId: data.userDetailId})
        })
        let datObj =  {
          jobId: this.jobId,
          visitId: parseInt(this.$route.params.visitId),
          assignToList: final
        }
      MyJobApp.addUpdateVisitAssig(
        datObj,
        response => {
          document.body.style = 'overflow: visible;'
          this.$root.$emit('addUpdateTeamHandler', true)
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    }
  },
}
</script>
<style scoped>

</style>