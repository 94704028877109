<template>
    <div v-if="visitDetail !== null" class="mt-4">
    <div class=" relative mb-4 card top_primary_card cursor-pointer bg-primary font-bold text-white p-2 rounded-xl  w-full ">
      <div class="flex justify-center items-center">
        <h1 class="heading-1 text-center">Visit {{visitDetail.visitNumber}}/{{visitDetail.totalVisits}} for Job #{{visitDetail.jobNumber}}</h1>
      </div>
      <!-- <span class="cursor-pointer text-white heading-3 absolute top-2 right-3"><i class="fa-solid fa-pen-to-square"></i></span> -->
    </div>
    <div class="text-text1  card rounded-xl bg-white mb-4 p-4" v-if="visitDetail.isCompleted && visitDetail.invoiceId === 0 && visitItems.length === 0">
      <div class="relative">
          <p class=" heading-3 font-bold mb-2 text-error">Action Required</p>
          <p class="text-gray4 whitespace-pre-line">This visit is completed and has line items that are not invoiced, please create invoice or delete line items</p>
        </div>
      </div>
    <div class="mb-4 flex flex-wrap gap-4">
        <!-- <Button v-if="userStartTime === null" :btnSize="'medium'" :textColor="'white'" :btnColor="'blue1'" :btnText="'Start Time'" @buttonAction="starVisitNow()"/> -->
        
        <!-- <button @click="stopVisitNow" style="min-width: 120px" v-else class="flex items-center justify-center cursor-pointer hover:shadow-xl border border-primary global_btn bg-white hover:bg-opacity-75 text-primary py-2 px-5 cta-text rounded-xl">
          <div class=""><i class="fa-regular fa-circle-stop"></i></div>
          <div class="w-20">{{startTimeCounter}}</div>
        </button> -->

        <Button v-if="!visitDetail.isCompleted" :btnSize="'medium'" :textColor="'white'" :btnColor="'warning'" :btnText="'Complete Visit'" @buttonAction="completeVisitBtn"/>
     
        <Button v-else :btnSize="'medium'" :textColor="'white'" :btnColor="'warning'" :btnText="'Reopen visit'" @buttonAction="reopenedVisit"/>
     
      
        <!-- <button style="min-width: 120px" v-else class="shadow flex items-center justify-center cursor-pointer hover:shadow-xl global_btn bg-success hover:bg-opacity-75 text-white py-2 px-5 cta-text rounded-xl">
          <div class="text-white mr-2"><i class="fa-solid fa-check"></i></div>
          <div class="w-20">Completed</div>
        </button> -->

        <Button  :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete Visit'" @buttonAction="isDeleteVisit= true"/>
        <Button :iconClass="'fas fa-message'"  :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Send Text'" @buttonAction="sendETxtBtn()"/>

    </div>
    <div>
      <div class="lg:grid grid-cols-3 gap-4">
        <div>
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class=" relative">
              <p  class="  font-bold mb-2">Schedule</p>
              <p v-if="!allDay" class="text-gray4">{{visitDetail.visitStartDateTime | logTimeShow}} - {{visitDetail.visitEndDateTime | leaveTimeFilterForActivity}}</p>
              <p v-else class="text-gray4">{{visitDetail.visitStartDateTime | allDayTimeFill}}: Full-Day</p>
              <p class="text-success font-bold mt-2" v-if="visitDetail.isCompleted">Visit Completed</p>
              <span @click="updateSchedule = true" class="pl-2 cursor-pointer text-primary absolute top-0 right-0"><i class="fa-solid fa-pen-to-square"></i></span>
            </div>
          </div>
          <div class=" card rounded-xl bg-white mb-4 p-4">
            <div class="pb-2 md:flex lg:block  2xl:flex items-center justify-between">
              <span @click.stop="jobDetail()" class="cursor-pointer text-text1 hover:text-primary font-bold heading-4">{{visitDetail.jobTitle}}</span>
            <div class="font-normal heading-7">
                    <div class="flex items-center justify-between text-text2">
                      <p>{{visitDetail.jobTypeName}}  Est: {{ setDisplayEstimation(visitDetail.jobEstimation) }} // Logged {{ setDisplayEstimation(visitDetail.totalJobVisitLogHrs/60) }}</p>
                      
                    </div>
                  </div>
            </div>
            <span class="cursor-pointer text-primary" @click.stop="customerDetail()">{{visitDetail.companyName}}</span>
            <div v-if="visitDetail.customerContactNumber !== ''" class=" flex justify-between">
              <span>{{visitDetail.customerContactNumber}}</span>
              <div class="flex gap-4">
                <a class="text-primary" :href="`tel:${visitDetail.customerContactNumber}`"><i class="fa-solid fa-phone-volume"></i></a>
                <a class="text-primary" :href="`sms:${visitDetail.customerContactNumber}`"><i class="fa-solid fa-comment"></i></a>
              </div>
            </div>
          </div>
          <!-- <div class="">
            <div class=" font-bold heading-4">
              {{visitDetail.jobTitle}}
            </div>
          </div> -->
          <div v-if="visitDetail.latitude !== 0 && visitDetail.longitude !== 0" class="card bg-white rounded-xl p-4 mb-4">
              <div class="flex justify-between items-center mb-2">
                  <p class="font-bold">Address</p>
              </div>
              <div class="">
                  <!-- <div class="mb-4 flex whitespace-nowrap items-start" >
                    <div>
                      <span @click="openMap(visitDetail.latitude, visitDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2"></span></span>
                    </div>
                    <div class="pl-1">
                        <p class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap">
                        <span v-if="visitDetail.fullAddress !== ''">{{visitDetail.fullAddress}}</span>
                        <span v-else>{{visitDetail.addressLine1}}</span>
                        </p>
                        <p  class="text-text1 heading-6">
                          <span v-if="visitDetail.houseNo !== ''">#: {{visitDetail.houseNo}}</span>
                          <span v-if="visitDetail.houseNo !== ''"> (Floor: {{visitDetail.floorNumber}})</span>
                        </p>
                        <p v-if="visitDetail.buildingName!== ''" class="text-text1 whitespace-pre-wrap">Bldg: {{visitDetail.buildingName}}</p>
                        <p v-if="visitDetail.directionNote!== ''" class="text-text1 whitespace-pre-wrap">Directions: {{visitDetail.directionNote}}</p>
                        <p v-if="visitDetail.contactName!== ''" class="text-text1 whitespace-pre-wrap">Contact: {{visitDetail.contactName}}</p>
                        <p v-if="visitDetail.contactNumber!== ''" class="text-text1 whitespace-pre-wrap">Phone #: {{visitDetail.contactNumber | phone}}</p>
                    </div>
                  </div> -->
                  <div class="mb-4 flex whitespace-nowrap justify-between">
                    <div class="flex items-start">
                      <div>
                        <span @click="openMap(visitDetail.latitude, visitDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2">:</span></span>
                      </div>
                      <div class="pl-1 relative">
                          <p class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap cursor-pointer" @click="showJobDetail = !showJobDetail">
                          {{visitDetail.fullAddress}}
                          </p>
                          <div v-if="showJobDetail">
                            <p  class="text-text1 heading-6">
                              <span v-if="visitDetail.houseNo !== ''">#: {{visitDetail.houseNo}}</span>
                              <span v-if="visitDetail.houseNo !== ''"> (Floor: {{visitDetail.floorNumber}})</span>
                            </p>
                            <p v-if="visitDetail.buildingName!== ''" class="text-text1 ">Bldg: {{visitDetail.buildingName}}</p>
                            <p v-if="visitDetail.directionNote!== ''" class="text-text1 whitespace-pre-wrap">Directions: {{visitDetail.directionNote}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-start">
                        <span @click="showJobDetail = !showJobDetail" v-if="showJobDetail" class="text-primary cursor-pointer"><i class="fas fa-chevron-up"></i></span>
                        <p @click="showJobDetail = !showJobDetail" v-if="!showJobDetail" class="text-primary cursor-pointer"><i class="fas fa-chevron-down"></i></p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class=" relative">
              <p  class="  font-bold mb-2">Assign member(s)</p>
              <div class=" flex gap-2 flex-wrap">
                <div class="mb-4 flex flex-wrap cursor-pointer
                " v-for="(user, index) in visitDetail.assignToList" :key="index" @click="callAdminStartStop(user)" >
                  <Chip :class="`${user.jobStartTime !== '' ? ' font-semibold' : ''}`" :textColor="`${user.jobStartTime !== '' ? 'primary' : ''}`" :iconClass="`${user.jobStartTime !== '' ? 'fa-solid fa-circle-play' : ''}`" v-if="activeUer !== user.userDetailId" :chipText="`${user.firstName}  ${user.lastName}`"/>
                  <Chip :class="`${user.jobStartTime !== '' ? ' font-semibold' : ''}`" :textColor="`${user.jobStartTime !== '' ? 'primary' : ''}`" :iconClass="`${user.jobStartTime !== '' ? 'fa-solid fa-circle-play' : ''}`" v-else :chipText="`Me`"/>
                </div> 
              </div>
              <div class="flex justify-between sm:grid grid-cols-2 text-primary font-bold">
                <div><span @click="isShowTimeLog = true" class=" cursor-pointer">Time Log</span></div>
                <div><span @click="isLocationView = true" class=" cursor-pointer">Location</span></div>
              </div>
              <span @click="showAddUpdateTeam = true" class="pl-2 cursor-pointer text-primary absolute top-0 right-0"><i class="fa-solid fa-pen-to-square"></i></span>
            </div>
          </div>
          <div v-if="visitDetail.jobDescription !== ''" class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class=" relative">
              <p  class="  font-bold mb-2">Instructions</p>
              <p class="text-gray4 whitespace-pre-line">{{visitDetail.jobDescription}}</p>
            </div>
          </div>
        </div>
        <div class=" col-span-2">
          <div class="card bg-white rounded-xl p-4 mb-4">
              <div>
                  <div class="mb-4">
                      <Tabs :tabsList="tabTitle" />
                  </div>
                  <div v-if="selectedName === 'Notes'">
                    <div v-if="visitDetail !== null && canNotePermission" class="card rounded-xl bg-white mb-4 p-4">
                      <NotesList :detailId="parseInt(this.$route.params.visitId)" :requestType="'visit'" :noteTitle="'Visit Notes'" />
                    </div>
                  </div>
                  <div v-if="selectedName === 'Invoice'">
                    <div class="card p-4 bg-white rounded-xl mb-4" v-if="!visitDetail.isLead && visitDetail.invoiceId === 0">
                      <LineItems :detailData="{
                        customerId:visitDetail.customerId,
                        jobId:visitDetail.jobId,
                        visitId:visitDetail.visitId,
                        jobTitle: visitDetail.jobTitle,
                        visitNumber: visitDetail.visitNumber,
                        requestType: 'visit',
                        }" />
                    </div> 
                    <div v-else @click="$router.push({name: 'invoiceDetails', params: {invoiceId: visitDetail.invoiceId}})" class="card p-4 bg-white rounded-xl mb-4 hover:bg-screen_bg cursor-pointer">
                      <div class="text-text1">
                        <p>Invoice</p>
                        <p>{{ visitDetail.totalAmount | amountOnlyNumber}}</p>
                        <p>{{ visitDetail.invoiceNumber}} 
                          <span :style="`color: ${visitDetail.invoiceStatusColor}`">
                            ({{visitDetail.invoiceStatus}})
                          </span>
                        </p>
                      </div>
                    </div> 
                  </div>
                  <div v-if="selectedName === 'Checklist'">
                    <div class="card p-4 bg-white rounded-xl mb-4">
                      <div class="text-text1">
                        <div class="flex justify-between items-center mb-2">
                          <p class="text-text1 font-bold heading-4 ">Visit Checklist</p>
                          <Button @buttonAction="updateChecklist=true; checkLiTitle = ''" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Update'"/>
                        </div>
                        <div v-if="visitChecksList.length > 0">
                        <div v-for="(chl, ind) in visitChecksList" :key="ind" class="mb-2 ml-4" :class="`${visitChecksList.length - 1 > ind  ? 'border-b border-gray1' : ''}`">
                          <div class="mb-1 flex items-center">
                            <div style="width: 25px" class="text-primary"><i v-if="chl.checked" class="fa-solid fa-circle-check"></i></div>
                            {{chl.checkLiTitle}}</div>
                        </div>
                        </div>
                        <div v-else>
                          <NoRecords :alertMessage="` No records found.`" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="selectedName === 'Tools'">
                    <div class="card p-4 bg-white rounded-xl mb-4">
                      <div class="text-text1 mb-4">
                        <div class="flex justify-between items-center mb-2">
                          <p class="text-text1 font-bold heading-4 ">Visit Tools</p>
                          <Button @buttonAction="isUpdateToolist=true" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'+ Add Tools'"/>
                        </div>
                        <div v-if="visitDetail.toolList.length > 0">
                        <div v-for="(tool, ind) in visitDetail.toolList" :key="ind" class="mb-2" :class="`${visitDetail.toolList.length - 1 > ind  ? 'border-b border-gray1' : ''}`">
                          <p class="mb-1">{{tool.toolName}}</p>
                          <p class="mb-1 heading-7 text-text2">{{tool.toolDescription}}</p>
                        </div>
                        </div>
                        <div v-else>
                          <NoRecords :alertMessage="` No records found.`" />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          
          <!-- <div class="card p-4 bg-white rounded-xl mb-4">
            <div class="flex my-2 justify-between items-center text-text1">
              <p class=" font-bold">Check Items ({{ visitCheckList.length }})</p>
              <div class="heading-5" v-if="!visitDetail.isCompleted">
                <span  @click="selectInvItems" class="text-primary font-bold cursor-pointer">+ Add Check</span>
              </div>
            </div>
            <div v-for="(checks, index) in visitCheckList" :key="index">
              <div class=" py-1 flex items-center">
                <li class="text-gray4">{{checks.checkListTitle}}</li>
                <span class="text-success pl-4" v-if="checks.isItemChecked"><i class="fas fa-check"></i></span>
                <p class="text-error pl-4" v-if="!checks.isItemChecked"><i class="fas fa-close"></i></p>
              </div>
            </div>
            <div class="mt-2 py-2 heading-5">
                <span v-if="sendObject.itemList.length === 0" @click="selectInvItems" class="text-primary font-bold mt-2 cursor-pointer">+ Add Item</span>
                <span v-else  @click="selectInvItems" class="text-primary font-bold mt-2 cursor-pointer">+ Add more Item</span>
              <p v-if="errObj.itemErr !== ''" class="text-error heading-7">{{errObj.itemErr}}</p>
            </div>
          </div> -->
          
        </div>
      </div>
    </div>
    <div class="popup_overlay px-4" v-if="showListing">
      <div class="custom_dialog rounded-xl" style="width: 400px; overflow: auto; max-height: 90%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between items-center p-3">
              <div class=" font-medium text-white heading-3">
                Check List
              </div>
            </div>
            <div class="p-2">
              <div v-for="(item, i) in showItemListing" :key="i + 'i'" class="card rounded-xl bg-white mb-2 p-3">
                <div class="flex items-center ">
                  <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary" @click="checkboxCheck(item)" v-model="item.isSelected" >
                  <span class="pl-3 text-text2 heading-5 cursor-pointer" @click="checkboxCheck(item)">{{item.checkListTitle}}</span>
                </div>
              </div>
            </div>
            <div class="flex justify-end p-3">
              <div class="text-rigth flex gap-2">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="nextStep(false)"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Next'" @buttonAction="nextStep(true)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_overlay px-4" v-if="showinvoiceError">
      <div class="custom_dialog rounded-xl" style="width: 500px; overflow: auto; max-height: 90%;">
        <div class="relative bg-white">
          <div>
            <div class="p-4">
              <p class="heading-4 text-text2">No invoice found in this visit, please add line items and convert to invoice if it is a billable visit</p>
            </div>
            <div class="flex justify-end p-3">
              <div class="text-rigth flex gap-2">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="nextStep2(false)"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Ignore and Countinue'" @buttonAction="nextStep2(true)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_overlay px-4" v-if="isUpdateToolist">
      <div class="custom_dialog rounded-xl" style="width: 500px; overflow: auto; max-height: 90%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between p-3 rounded-t-xl">
            <div class=" font-medium text-white heading-3">
                Tool list
            </div>
            <div @click="addNewTool=true" class=" font-medium text-white heading-3 cursor-pointer">
                Add New
            </div>
          </div>
             <div style="" class="p-4">
              <div class="mb-4 sm:mb-0">
                <div >
                <div v-for="(item, ch) in toolsList" :key="ch" :class="` ${toolsList.length - 1 >  ch ? 'border-b' : ''} py-2 mb-2 flex items-center cursor-pointer hover:bg-screen_bg`" @click="item.checked = !item.checked">
                   <div v-if="item.checked" style="width: 25px" class="text-primary"><i class="fa-solid fa-circle-check"></i></div>
                   <p v-else style="width: 25px" class="text-primary"></p>
                  <span>{{item.toolName}}</span>
                </div>
                
                </div>
              </div>
            </div>
            <div class="flex justify-end p-3">
              <div class="text-rigth flex gap-2">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="isUpdateToolist=false"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="UpdateToollist"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_overlay px-4" v-if="addNewTool">
      <div class="custom_dialog rounded-xl" style="width: 500px; overflow: auto; max-height: 90%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between p-3 rounded-t-xl">
            <div class=" font-medium text-white heading-3">
              Add new tool
            </div>
          </div>
             <div class="p-4">
              <div class="mb-4 sm:mb-0">
                <div >
                <div class="flex items-center">
                  <TextField class="w-full"
                  :inputId="`Toolnameid`"
                  :textMaxlength="100"
                  :inputext="toolNameText"
                  :placholderText="`Tool Name`"
                  :lableText="'Tool Name'"
                  :autoFocus="true"
                  @inputChangeAction="(data) => (toolNameText = data)"
                />
                
                
                </div>
                <div class="mt-4">
                  <TextAreaInput
                    :inputId="'diswcriptioncon'"
                    :inputext="toolDescription"
                    :placholderText="`Tool Description`"
                    :lableText="'Tool Description'"
                    :inputType="'text'"
                    :autoFocus="false"
                    :cols="50"
                    :rows="3"
                    :setReadOnly="false"
                    @inputChangeAction="(data) => toolDescription = data"  />
                </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end p-3">
              <div class="text-rigth flex gap-2">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="addNewTool=false"/>
                <Button :btnSize="'medium'" :disabled="toolNameText.trim() === ''" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="AddnewToool"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_overlay px-4" v-if="updateChecklist">
      <div class="custom_dialog rounded-xl" style="width: 500px; overflow: auto; max-height: 90%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between p-3 rounded-t-xl">
            <div class=" font-medium text-white heading-3">
                Update Checklist
            </div>
          </div>
             <div class="p-4">
              <div class="mb-4 sm:mb-0">
                <div >
                <div v-for="(item, ch) in visitChecksList" :key="ch" :class="` ${visitChecksList.length - 1 >  ch ? 'border-b' : ''} py-2 mb-2 flex items-center`" @click="item.checked = !item.checked">
                  <CheckBoxInput :checkValue="item.checked" :label="``" :size="'small'" :disabled="false" @setIsActive="item.checked = !item.checked"/>
                  <span>{{item.checkLiTitle}}</span>
                </div>
                <div class="flex items-center mt-4">
                  <TextField class="w-full"
                  :inputId="`chekTitle`"
                  :textMaxlength="100"
                  :inputext="checkLiTitle"
                  :placholderText="`Chekclist Title`"
                  :lableText="''"
                  :autoFocus="true"
                  @inputChangeAction="(data) => (checkLiTitle = data)"
                />
                <span @click="addChecklist"
                    :class="`${checkLiTitle.trim() !== '' ? 'text-primary' : 'text-gray2' } cursor-pointer heading-3 pl-4`" >
                    Add
                    </span>
                </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end p-3">
              <div class="text-rigth flex gap-2">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="updateChecklist=false"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="UpdateCHekclist"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SendTextGlobal :visitDetail="visitDetail" v-if="sendText"/>
    <CheckList :itemsSelected="visitCheckList" v-if="itemSelect" :object="{visitId: parseInt(this.$route.params.visitId),jobId: visitDetail.jobId}" />
    <ConfirmBox v-if="isDeleteVisit"  :message="'Once deleted cannot undone'" :title="'Are you sure?'" />
    <AddUpdateTeam :jobId="visitDetail.jobId" :selectedTeam="visitDetail.assignToList" v-if="showAddUpdateTeam" />
    <UpdateSchedule :visitDetail="visitDetail" v-if="updateSchedule" />
    <CompleteVisit :visitDetail="visitDetail" :checkListArr="showItemListing" v-if="isCompleteVisit" />
    <TimeLog :visitDetail="visitDetail" v-if="isShowTimeLog" />
    <FullScreeenLocation :visitDetail="visitDetail" v-if="isLocationView" />
    <AdminStartStopVisit v-if="isAdminStarstop" :visitDetail="visitDetail" :selectedMemberData="selectedMemberData" />
   </div>
</template>
<script>
import {FilterPermissions} from '@/utils/Permissions.js'
import MyJobApp from '@/api/MyJobApp.js'
import Chip from '../../components/chip.vue';
import Button from '@/View/components/globalButton.vue'
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import SendTextGlobal from '@/View/components/sendtextglobal.vue'
import CheckList from '@/View/job/visit/components/checkList.vue';
import LineItems from '@/View/job/visit/components/LineItems.vue';
import NotesList from './NotesList.vue';
import AddUpdateTeam from './components/AddUpdateTeam.vue';
import moment from 'moment'
import UpdateSchedule from './components/UpdateSchedule.vue';
import CompleteVisit from './components/CompleteVisit.vue';
import TimeLog from './components/TimeLog.vue';
import CheckBoxInput from '@/View/components/checkBoxInput.vue'
import TextField from "@/View/components/textfield.vue";
import FullScreeenLocation from './components/FullScreeenLocation.vue';
import AdminStartStopVisit from './components/AdminStartStopVisit.vue';
import Tabs from '../../components/tabs.vue';
import NoRecords from '../../components/noRecords.vue';
import TextAreaInput from '../../components/textAreaInput.vue';
var checkTimeInterval = null
export default {
  name: "lead",
  components: {
    SendTextGlobal,
    TextField,
    CheckBoxInput,
    ConfirmBox,
    NoRecords,
    Chip,
    Button,
    CheckList,
    LineItems,
    NotesList,
    AddUpdateTeam,
    UpdateSchedule,
    CompleteVisit,
    TimeLog,
    FullScreeenLocation,
    AdminStartStopVisit,
    Tabs,
    TextAreaInput,
  },
  data() {
    return {
      showJobDetail: false,
      sendText: false,
      showinvoiceError: false,
      selectedName: 'Notes',
      showListing: false,
      visitCheckList: [],
      showItemListing: [],
      itemSelect: false,
      isJobPermission: FilterPermissions('job'),
      isCustomerPermission: FilterPermissions('customer'),
      canNotePermission: FilterPermissions('visit_note'),
      canVisitCompPermission: FilterPermissions('visit_sign'),
      visitItems: [],
      visitChecksList: [],
      selectedMemberData: null,
      isAdminStarstop: false,
      isLocationView: false,
      isShowTimeLog: false,
      isCompleteVisit: false,
      allDay: false,
      updateSchedule: false,
      showAddUpdateTeam: false,
      isDeleteVisit: false,
      visitDetail: null,
      userStartTime: null,
      startTimeCounter: '',
      activeUer: 0,
      tabTitle: [
        {name: 'Notes', active: true, canViewPermission: true},
        {name: 'Invoice', active: false, canViewPermission: true},
        {name: 'Checklist', active: false, canViewPermission: true},
        {name: 'Tools', active: false, canViewPermission: true},
      ],
      updateChecklist: false,
      checkLiTitle: '',
      isUpdateToolist: false,
      addNewTool: false,
      toolsList: [],
      toolNameText: '',
      toolDescription: '',
    };
  },
  props: ["customerId"],
  created() {
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo')).userDetailId
  },
  mounted() {
    this.$root.$on("closeSendTextPopup", () => {
      this.sendText = false
      document.body.style = 'overflow: visible;'
    });
    this.getVisitInfo()
     this.$root.$on('getVisitItemsList', (data) => {
       this.visitItems = data
       console.log('this.visitItems', this.visitItems)
      document.body.style = 'overflow: visible;'
    })
     this.$root.$on('confirmBoxHandler', (data) => {
      if (data && this.isDeleteVisit) {
        this.deleteVisit()
      }
      document.body.style = 'overflow: visible;'
      this.isDeleteVisit = false
    })
    this.$root.$on('addUpdateTeamHandler', (data) => {
      if (data && this.showAddUpdateTeam) {
       this.getVisitInfo()
      }
      this.showAddUpdateTeam = false
    })
    this.$root.$on('sheduleUpdateHandler', (data) => {
      if (data && this.updateSchedule) {
       this.getVisitInfo()
      }
      this.updateSchedule = false
    })
    this.$root.$on('completeVisitHandler', (data) => {
      document.body.style = 'overflow: visible;'
      if (data && this.isCompleteVisit) {
       this.visitDetail = null
       this.getVisitInfo()
       this.getCheckList()
      }
      this.isCompleteVisit = false
    })
    this.$root.$on('visitTimeLogHandler', () => {
      this.isShowTimeLog = false
    })
    this.$root.$on('locationViewHandler', () => {
      this.isLocationView = false
    })
    this.$root.$on('adminVisitStartStopHandler', (response) => {
      if (response && this.isAdminStarstop) {
        this.getVisitInfo()
      }
      this.isAdminStarstop = false
    })
    this.$root.$on("itemSelectionHandler2", (response) => {
      document.body.style = 'overflow: visible;'
      this.itemSelect = false
      if (response === true) {
        this.getCheckList()
      }
    });
    this.getCheckList()
    this.$root.$on('setNewTabActive', (index) => {
        this.tabsSet(index)
    })
  },
    beforeDestroy () {
      this.$root.$off("closeSendTextPopup");
      this.$root.$off("setNewTabActive");
      this.$root.$off("itemSelectionHandler2");
      this.$root.$off("locationViewHandler");
      this.$root.$off("visitTimeLogHandler");
      this.$root.$off("completeVisitHandler");
      this.$root.$off("confirmBoxHandler");
      this.$root.$off("getVisitItemsList");
      this.$root.$off("addUpdateTeamHandler");
      this.$root.$off("sheduleUpdateHandler");
    clearInterval(checkTimeInterval)
    },
  methods: {
    setDisplayEstimation (value) {
      if (value > 0) {
          let estimation = value
      let hours = Math.floor(estimation / 60); // Get the whole number of hours
      let min = estimation % 60; // Get the remaining minutes
      let minutes = Math.round(min); // Get the remaining minutes
      let formattedTime = `${ hours > 0 ? hours.toString().padStart(2, '0') + 'h' : ''} ${hours > 0 && minutes > 0 ? ":" : ''}`+`${minutes > 0 ? ` ${minutes.toString().padStart(2, '0')} m` : ''}`;

      return formattedTime
      } else {
        return '0'
      }
      
    },
    sendETxtBtn () {
      document.body.style = 'overflow: hidden;'
      this.sendText = true
    },
    addChecklist () {
      this.visitChecksList.push(
        {
          answer: '',
          checkLiTitle: this.checkLiTitle.trim(),
          checked: false,
        }
      )
      this.checkLiTitle = ''
    },
    AddnewToool () {
      if (this.toolNameText.trim() !== '') {
        this.toolsList.push({
          toolMasterId: 0,
          jobVisitToolMappId: 0,
          toolName: this.toolNameText,
          toolDescription: this.toolDescription,
          checked: true,
          isPickedUpUser: 0,
          pickedUpDateTime: new Date().toUTCString(),
        })
      }
      this.addNewTool = false
      this.toolDescription = ''
      this.toolDescription = ''
    },
    UpdateToollist () {
      let toolli = []
      this.toolsList.forEach(e => {
        if (e.checked) {
          toolli.push({
          toolMasterId: 0,
          toolName: e.toolName,
          toolDescription: e.toolDescription,
          isPickedUp: e.checked,
          isPickedUpUser: this.activeUer,
          pickedUpDateTime: '',
        })
        }
      });
  let data = {
          jobId: this.visitDetail.jobId,
          visitId: parseInt(this.$route.params.visitId),
          toolList: toolli
        }

console.log('data', data);

      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.updateVisitToolPicWeb(
        data,
          response => {
            this.getVisitInfo()
            this.isUpdateToolist = false
            this.$store.dispatch('SetLoader', {status: false, message: response.message})
          },
          (err) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    UpdateCHekclist () {
      let checkli = []
      this.visitChecksList.forEach(e => {
        checkli.push({
          checkLiTitle: e.checkLiTitle,
          answer: e.checked ? 'Yes' : ''
        })
      });
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.updateVisitChecklist(
        parseInt(this.$route.params.visitId),
        checkli,
          response => {
            this.getVisitInfo()
            this.updateChecklist = false
            console.log('ressssssssssssss', response);
            this.$store.dispatch('SetLoader', {status: false, message: response.message})
          },
          (err) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    tabsSet (index) {
      this.tabTitle.forEach((element, i) => {
          if (index === i) {
              element.active = true
              this.selectedName = element.name
          } else {
              element.active = false
          }
      });
    },
    checkboxCheck (data) {
      data.isSelected = !data.isSelected
      // let count = 0
      // for (let index = 0; index < this.showItemListing.length; index++) {
      //   if (this.showItemListing[index].isSelected) {
      //     count += 1
      //   }
      // }
      // console.log('count', count)
      // console.log('this.showItemListing.length', this.showItemListing.length)
      // if (count === this.showItemListing.length) {
      //   this.isBtnDisable = false
      // } else {
      //   this.isBtnDisable = true
      // }
    },
    nextStep2 (data) {
      this.showListing = false
      this.showinvoiceError = false
      if (data) {
        this.isCompleteVisit = true
      } else {
        document.body.style = 'overflow: visible;'
      }
    },
    nextStep (data) {
      if (data) {
        if (this.visitDetail.invoiceId === 0 && this.visitItems.length === 0) {
          this.showinvoiceError = true
        } else {
          this.isCompleteVisit = true
        }
      } else {
        document.body.style = 'overflow: visible;'
      }
      this.showListing = false
    },
    completeVisitBtn () {
      if (this.visitCheckList.length > 0) {
        // this.isBtnDisable = true
        this.showItemListing = []
        this.showItemListing = JSON.parse(JSON.stringify(this.visitCheckList))
        this.showListing = true
        document.body.style = 'overflow: hidden;'
      } else {
        if (this.visitDetail.invoiceId === 0 && this.visitItems.length === 0) {
          this.showinvoiceError = true
        } else {
          this.isCompleteVisit = true
        }
      }
    },
    selectInvItems () {
      this.itemSelect = true
    },
    jobDetail () {
      if (this.isJobPermission) {
        this.$router.push({name: 'jobDetail', params: {jobId: this.visitDetail.jobId}})
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Job Detail`})
      }
    },
    customerDetail () {
      if (this.isCustomerPermission) {
        this.$router.push({name: 'customerDetails', params: {customerId: this.visitDetail.customerId}})
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Customer Detail`})
      }
    },
    callAdminStartStop (userData) {
      this.selectedMemberData = userData
      this.isAdminStarstop = true
    },
    starVisitNow () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.startJobVisit(
        parseInt(this.$route.params.visitId),
          response => {
            this.getVisitInfo()
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    getCheckList () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetChecklistInVisitDetail(
        parseInt(this.$route.params.visitId),
          response => {
            let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
            temp.forEach(element => {
              if (element.isItemChecked === true) {
                element.isSelected = true
              } else {
                element.isSelected = false
              }
            });
            this.visitCheckList = temp
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (err) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    stopVisitNow () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.stopJobVisit(
        parseInt(this.$route.params.visitId),
          response => {
            clearInterval(checkTimeInterval)
            this.userStartTime = null
            this.getVisitInfo()
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    startVisit (startValue) {
      let value = new Date(startValue) 
      let self = this
      checkTimeInterval = setInterval(function () {
        var now = new Date().getTime()
        var distance = now - value 
        var hr = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        let hours = hr === 0 ? '00' : hr <= 9 ? '0' + hr : hr
        var min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        let minutes = min === 0 ? '00' : min <= 9 ? '0' + min : min
        var sec = Math.floor((distance % (1000 * 60)) / 1000)
        let seconds = sec === 0 ? '00' : sec <= 9 ? '0' + sec : sec
        self.startTimeCounter = hours + ':' + minutes + ':' + seconds
      }, 1000)
    },
    openMap (lat, lng) {
     let url = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng
      window.open(url, '_blank')
    },
    getVisitInfo () {
      this.visitDetail = null
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getVisitDetail(
        parseInt(this.$route.params.visitId),
          response => {
            this.visitDetail = response.Data
            console.log("JSON.parse(response.Data.visitCheckList)", response.Data);
            let temp = response.Data.visitCheckList !== '' ? JSON.parse(response.Data.visitCheckList) : []
            temp.forEach(e => {
              e.checked =  e.answer ? e.answer === 'Yes' ? true : false : false
            });
            this.visitChecksList = temp
              console.log("JSON.parse(response.Data.visitCheckList)", temp);
            response.Data.assignToList.map(data => {
              if (data.userDetailId === this.activeUer) {
                if (data.jobStartTime !== '') {
                  this.userStartTime = data.jobStartTime
                  this.startVisit(data.jobStartTime)
                }
              }
            })

            var startTime = moment(response.Data.visitStartDateTime)
            var endTime = moment(response.Data.visitEndDateTime)
            var hoursDiff = endTime.diff(startTime, 'hours')
            var minutesDiff = endTime.diff(startTime, 'minutes') % 60
            console.log('hoursDiff', hoursDiff)
            console.log('hoursDiff minutesDiff', minutesDiff)
            if ((hoursDiff === 23 && minutesDiff === 59) || (hoursDiff === 24 && minutesDiff === 0)) {
              this.allDay = true
            } else {
              this.allDay = false
            }
            this.getToolList()
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (err) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    deleteVisit () {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.visitDelete(
        parseInt(this.$route.params.visitId),
          (response) => {
            this.$router.go(-1)
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    reopenedVisit () {
      this.$store.dispatch("SetLoader", { status: true, message: "Loading..." })
        MyJobApp.visitComplete(
          'open',
          '',
          parseInt(this.$route.params.visitId),
          [],
          '',
          false,
          (response) => {
            this.getVisitInfo()
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (error) => {
            this.$store.dispatch("SetAlert", { status: true, message: error.message, color: 'error'});
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        )
    },
    getToolList () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetToolsList(
          response => {
            this.toolsList = []
            if (response.Data !== null) {
              response.Data.forEach(e => {
                const findex = this.visitDetail.toolList.findIndex(x => x.toolName === e.toolName)
                if (findex > -1) {
                  this.toolsList.push({...e, checked: true})
                } else {
                   this.toolsList.push({...e, checked: false})
                }
              });
              this.visitDetail.toolList.forEach(e => {
                const findex = response.Data.findIndex(x => x.toolName === e.toolName)
                console.log('check 1111', findex);
                if (findex < 0) {
                  this.toolsList.push({...e, checked: true})
                }
              });
            }
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    }
  }
};
</script>
<style scoped>
</style>